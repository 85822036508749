<template>
  <div class="pagePanel">
    <section-panel-main></section-panel-main>
  </div>
</template>

<script>
import SectionPanelMain from '@/components/SectionPanel/SectionPanelMain.vue'
export default {
    components:{ SectionPanelMain }
}
</script>

<style scoped>
.pagePanel {
    padding: 40px 243px;
}

</style>