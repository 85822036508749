<template>
  <div class="createObject" v-if="isLoading">
    <div class="createObject__content">
      <p class="createObject__title">Объект</p>
      <div class="createObject__info">
        <label class="createObject__label">Введите название:</label>
        <input
          class="createObject__input"
          type="text"
          placeholder="Пример: 1-комнатная кв. 38 м² 2/9 этаж "
          v-model="name"
        />
      </div>
      <div class="createObject__info">
        <label class="createObject__label">Адрес:</label>
        <input
          class="createObject__input"
          type="text"
          placeholder="Пример: Киров, Ленинский район, улица Калинина, 40"
          v-model="adress"
        />
      </div>
      <div class="createObject__info">
        <label class="createObject__label">Описание объявления:</label>
        <textarea
          class="createObject__area"
          type="text"
          placeholder="Пример: Продам однокомнатную уютную, светлую и очень теплую квартиру в ЖК..."
          v-model="des"
        ></textarea>
      </div>
      <div class="createObject__info">
        <label class="createObject__label">Цена:</label>
        <input
          class="createObject__input"
          type="number"
          placeholder="Пример: 3000"
          v-model="price"
        />
      </div>
      <div class="createObject__info">
        <label class="createObject__label"
          >Ваше имя( кто размещает объявление ):</label
        >
        <input
          class="createObject__input"
          type="text"
          placeholder="Пример: Олег"
          v-model="surname"
        />
      </div>
      <div class="createObject__info">
        <label class="createObject__label">Популярность:</label>
        <input
          class="createObject__input"
          type="number"
          placeholder="Пример: 10"
          v-model="rating"
        />
      </div>
      <div class="createObject__photo">
        <div class="createObject__download">
          <p class="createObject__name">Фотографии:</p>
          <p class="createObject__des">Не более 30 и не меньше 5</p>
          <p class="createObject__des">1 фото - основное</p>
          <input
            class="createObject__file"
            type="file"
            @change="onFileChange"
            multiple
          />
          <button class="createObject__post" @click="upload">Разместить</button>
        </div>
        <div>
          <draggable-item
            :listImages="listImages"
            @delete="updateImages"
            @moved="movedElements"
          ></draggable-item>
        </div>
      </div>
    </div>
  </div>
  <div class="createObject__loading" v-else>
    <spinner></spinner>
  </div>
</template>

<script>
import axios from "axios";
import Spinner from "../UI/Spinner.vue";
import DraggableItem from "@/components/SectionCreateObject/DraggableItem.vue";
export default {
  components: { Spinner, DraggableItem },
  data() {
    return {
      listImages: [],
      newFormData: new FormData(),
      counter: 0,
      fileIn: null,
      name: "",
      adress: "",
      des: "",
      price: "",
      surname: "",
      rating: "",
      isLoading: true,
      isMoved: true,
      isDeleted: true,
    };
  },
  methods: {
    onFileChange(e) {
      const files = Array.from(e.target.files).reverse();
      for (let i = 0; i < files.length; i++) {
        this.fileIn = files[i];
        this.listImages.push({ src: URL.createObjectURL(files[i]) });
        this.newFormData.append(`photos`, this.fileIn);
        this.counter += 1;
      }
    },
    async upload() {
      try {
        if (
          this.name !== "" &&
          this.adress !== "" &&
          this.des !== "" &&
          this.price !== "" &&
          this.surname !== "" &&
          this.rating !== "" &&
          this.isMoved === true &&
          this.isDeleted === true
        ) {
          this.newFormData.append("name", this.name);
          this.newFormData.append("adress", this.adress);
          this.newFormData.append("des", this.des.toLowerCase());
          this.newFormData.append("price", this.price);
          this.newFormData.append("surname", this.surname);
          this.newFormData.append("rating", this.rating);
          this.isLoading = false;
          const response = await axios.post(
            this.$store.state.urlPage + "api/object-create",
            this.newFormData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          const list = await response.data;
          if (list) {
            this.isLoading = true;
          }
          if (list && this.isLoading) {
            alert(list);
            this.$router.push("/admin/panel");
          }
          this.newFormData = new FormData();
          this.listImages = [];
        } else {
          this.isLoading = true;
          alert("Заполните все поля");
        }
      } catch (e) {
        this.isLoading = true;
        this.listImages = [];
        alert(e.response.data);
        this.newFormData = new FormData();
      }
    },
    updateImages(idx, items) {
      this.isLoading = false;
      this.newFormData.forEach((e) => this.newFormData.delete("photos"));
      const readyAccept = confirm("Вы точно хотите удалить ?");
      if (readyAccept) {
        this.listImages.splice(
          this.listImages.findIndex((el) => el.src === idx.src),
          1
        );
      }
      let counter = 0;
      const promises = items.map((url) =>
        fetch(url.src)
          .then((response) => response.blob())
          .then((blob) => {
            counter += 1;
            return new File([blob], `photo${counter}.jpg`);
          })
      );
      Promise.all(promises).then((files) => {
        files.forEach((el) => this.newFormData.append("photos", el));
        this.isLoading = true;
      });
    },
    movedElements(items) {
      this.isMoved = false;
      this.newFormData.forEach((e) => this.newFormData.delete("photos"));
      let counter = 0;
      const promises = items.map((url) =>
        fetch(url.src)
          .then((response) => response.blob())
          .then((blob) => {
            counter += 1;
            return new File([blob], `photo${counter}.jpg`);
          })
      );
      Promise.all(promises).then((files) => {
        files.forEach((el) => this.newFormData.append("photos", el));
        this.isMoved = true;
      });
    },
  },
};
</script>

<style scoped>
.createObject__content {
  padding: 43px 243px;
}
.createObject__title {
  font-weight: 700;
  font-size: 48px;
  margin-bottom: 21px;
  color: #2c3136;
}
.createObject__info {
  display: flex;
  align-items: center;
  margin-bottom: 11px;
}
.createObject__label {
  font-weight: 500;
  font-size: 16px;
  color: #2c3136;
}
.createObject__input {
  border: 1px solid #2c3136;
  border-radius: 5px;
  background: white;
  padding: 15px;
  outline: none;
  min-width: 350px;
  margin-left: 15px;
  font-weight: 500;
  font-size: 14px;
  color: #2c3136;
  transition: all 0.3s ease;
}
.createObject__input:focus {
  border: 1px solid #d7593c;
}
.createObject__area {
  border: 1px solid #2c3136;
  border-radius: 5px;
  background: white;
  padding: 15px;
  outline: none;
  min-width: 350px;
  margin-left: 15px;
  font-weight: 500;
  font-size: 14px;
  color: #2c3136;
}
.createObject__photo {
  display: flex;
  margin-top: 32px;
}
.createObject__download {
  position: relative;
  display: flex;
  flex-direction: column;
}
.createObject__name {
  font-weight: 600;
  font-size: 18px;
  color: #2c3136;
}
.createObject__des {
  font-weight: 500;
  font-size: 14px;
  margin: 3px 0;
  color: #868686;
}
.createObject__file {
  margin-top: 20px;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
}
.createObject__post {
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  background: #4caf50;
  border-radius: 5px;
  border: none;
  margin-top: 29px;
  padding: 15px 0;
  text-align: center;
  width: 180px;
  transition: all 0.3s ease;
  cursor: pointer;
}
.createObject__post:hover {
  transform: scale(0.98);
}
</style>
