import { createStore } from "vuex";

export default createStore({
  state: {
    startIdxSlider: 0,
    endIdxSlider: 1,
    currentIdxSlider: 0,
    currentIdxObjects: 1,
    urlPage: "https://xn--80adihqepfe.xn--p1ai/",
  },
  modules: {},
});
