<template>
  <div class="about" id="about">
    <div class="about__content">
        <div class="about__teg">
            <p class="about__title" data-aos="flip-up" data-aos-duration="800">О компании</p>
            <div class="about__img">
                <img src="@/assets/img/about/line2.svg">
            </div>
        </div>
        <p class="about__info" data-aos="slide-up" data-aos-duration="800">ИП "Золенкова"- лучшее риэлторское агентство Кирова и Области.<br> Опыт работы более 15 лет. Мы выполняем свою работу в
        любых<br> ситуациях, даже при отказе банков от ипотеки.</p>
        <div class="about__img">
            <img src="@/assets/img/about/line2.svg">
        </div>
        <div class="about__mortgage">
            <div class="about__information" data-aos="slide-right" data-aos-duration="800">
                <p class="about__block">ИПОТЕКА</p>
                <p class="about__name">Мы поможем Вам подать<br> заявку на ипотеку в Кирове<br> и Кировской области.</p>
                <p class="about__des">Ипотека без первоначального<br> взноса!</p>
                <div class="about__items">
                    <div class="about__icons">
                        <div class="about__galochka">
                            <img src="@/assets/img/about/galochka.svg">
                        </div>
                        <p class="about__text">100%</p>
                        <p class="about__under">результат</p>
                    </div>
                    <div class="about__icons2">
                        <div class="about__galochka">
                            <img src="@/assets/img/about/galochka.svg">
                        </div>
                        <p class="about__text">99%</p>
                        <p class="about__under">положительных<br>
                        отзывов</p>
                    </div>
                </div>
            </div>
            <div class="about__image" data-aos="slide-left" data-aos-duration="800">
                <img src="@/assets/img/about/girl.png">
            </div>
        </div>
    </div>
    <img class="about__down" src="@/assets/img/about/down.svg">
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.about {
    background: #F2F6FA;
    position: relative;
    overflow: hidden;
}
.about__content{
    padding: 101px 243px;
}
.about__teg {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.about__title {
    font-weight: 700;
    font-size: 46px;
    line-height: 133%;
    color: #2C3136;
}
.about__img {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}
.about__info {
    text-align: center;
    font-weight: 500;
    font-size: 26px;
    margin-top: 43px;
    color: #2C3136;
}
.about__mortgage {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-top: 10px;
}
.about__block {
    padding: 10px 18px;
    width: 101px;
    background: #D7593C;
    text-align: center;
    border-radius: 5px;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: #FFFFFF;
}
.about__name {
    font-weight: 600;
    font-size: 36px;
    margin-top: 24px;
    color: #2C3136;
}
.about__des {
    font-weight: 400;
    font-size: 21px;
    margin-top: 10px;
    color: #2C3136;
}
.about__items {
    display: flex;
    align-items: center;
    margin-top: 54px;
}
.about__icons {
    height: 146px;
}
.about__icons2 {
    height: 146px;
    margin-left: 60px;
}
.about__text {
    font-weight: 600;
    font-size: 36px;
    margin-top: 10px;
    color: #2C3136;
}
.about__under {
    font-weight: 400;
    font-size: 14px;
    color: #2C3136;
}
.about__image {
    margin-left: 200px;
    margin-bottom:80px;
}
.about__down {
    position: absolute;
    bottom: 0;
    left:0;
    width: 100vw;

}
@media (max-width:1700px) {
.about__content{
    padding: 81px 123px;
}
.about__image img {
    max-width: 400px;
}   
}
@media (max-width:1400px) {
.about__content{
    padding: 81px 123px;
}
.about__info {
    font-size: 22px;
    margin-top: 33px;
}
.about__name {
    font-size: 28px;
}
.about__des {
    font-size: 18px;
}
.about__text {
    font-size: 28px;
}
.about__image img {
    max-width: 350px;
}   
}
@media (max-width:1200px) {
.about__content{
    padding: 81px 0px;
}
.about__info {
    font-size: 20px;
    margin-top: 33px;
}
.about__information {
    margin-right: 50px;
}
.about__image {
    margin: 0;
}   
}
@media (max-width:916px) {
.about__content{
    padding: 81px 0px;
}
.about__title {
    font-size: 36px;
}
.about__info {
    font-size: 18px;
    margin-top: 33px;
}
.about__information {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-left: 50px;
}
.about__name {
    text-align: center;
    margin-bottom: 5px;
    font-size: 22px;
}
.about__des {
    text-align: center;
    font-size: 15px;
}
.about__image {
    display: none;
}   
}
@media (max-width:706px) {
.about__content{
    padding: 81px 0px;
}
.about__title {
    font-size: 36px;
}
.about__info {
    font-size: 2.55vw;
    margin-top: 33px;
}
.about__information {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-left: 50px;
}
.about__name {
    text-align: center;
    margin-bottom: 5px;
    font-size: 22px;
}
.about__des {
    text-align: center;
    font-size: 15px;
}
.about__image {
    display: none;
}   
}
@media (max-width:420px) {
.about__content{
    padding: 81px 0px;
}
.about__title {
    font-size: 20px;
}
.about__info {
    font-size: 2.55vw;
    margin-top: 33px;
}
.about__information {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-left: 50px;
}
.about__name {
    text-align: center;
    margin-bottom: 5px;
    font-size: 16px;
}
.about__des {
    text-align: center;
    font-size: 15px;
}
.about__text {
    font-size: 15px;
}
.about__under {
    font-size: 12px;
}
.about__image {
    display: none;
}   
}
</style>