<template>
  <div class="choicePanel">
    <div class="choicePanel__content">
        <p 
        v-for="(list, idx) in listItems"
        :key="list.id"
        class="choicePanel__text"
        @click="goActive(idx)"
        :class="{ activeItem : activeIdx === idx }"
        >{{ list }}</p>
    </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            listItems:[ 'Объекты', 'Посты', 'Отзывы' ],
            activeIdx:0,
        }
    },
    methods:{
        goActive( idx ) {
            this.activeIdx = idx
            this.$emit('activeBlock', this.listItems[idx])
        } 
    }
}
</script>

<style scoped>
.choicePanel {
    display: flex;
    align-items: center;
    justify-content: center;
}
.choicePanel__content {
    display: flex;
    align-items: center;
}
.choicePanel__text {
    font-weight: 600;
    font-size: 24px;
    margin: 0 11px;
    color: #2C3136;
    border: 2px solid #fff;
    padding-bottom: 5px;
    transition: all 0.3s ease;
    cursor: pointer;
}
.choicePanel__text:hover {
    color: #D7593C;
}
.activeItem {
    color: #D7593C !important;
    border-bottom: 2px solid #D7593C !important;
}
</style>