<template>
    <div class="panelItem">
        <div class="panelItem__content">
            <div class="panelItem__left">
                <p class="panelItem__id"><span class="panelItem__span">№</span>{{ idx + 1 }}</p>
                <p class="panelItem__id"><span class="panelItem__span">Имя/на клиента/ов:</span>{{ review.name }}</p>
            </div>
            <div class="panelItem__btn">
                <delete-button @click="deleteReview"></delete-button>
            </div>
        </div>
    </div>
</template>

<script>
import deleteButton from '@/components/SectionPanel/UI/delete-button.vue'
import axios from 'axios'
export default {
    props: {
        review: {
            type: Object
        },
        idx:{}
    },
    methods:{
        async deleteReview() {
            try {
                const response = axios.delete(this.$store.state.urlPage + 'api/review-delete', {
                    data:{
                        findfolder: this.review.findfolder
                    },
                    headers:{
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                })
                this.$emit('accessDelete', this.review)
                alert('Отзыв успешно удален')
            }
            catch(e) {
                alert( e.response.data )
            }
        }
    },
    components: { deleteButton },
}
</script>

<style scoped>
</style>