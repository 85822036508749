<template>
    <div class="news">
        <div class="news__content">
            <p class="news__quantity">{{ newsArray.length }} пост/а</p>
            <div class="news__menu">
                <news-item
                v-for="news in newsArray"
                :key="news.name"
                :news="news"
                ></news-item>
            </div>
        </div>
    </div>
</template>

<script>
import NewsItem from '@/components/SectionNews/item/NewsItem.vue'
import axios from 'axios'
export default {
    data() {
        return {
            newsArray:[]
        }
    },
    methods:{
        async loadPosts() {
            const response = await axios.get(this.$store.state.urlPage + 'api/get-posts')
            const list = await response.data
            this.newsArray = list
        }
    },
    mounted(){
        this.loadPosts()
    },
    components:{ NewsItem,  }
}
</script>

<style scoped>
.news {
    min-height: 70vh;
}
.news__content {
    padding: 54px 617px;
}
.news__quantity {
    font-weight: 600;
    font-size: 24px;
    color: #2C3136;
}
.news__menu {
    margin-top: 27px;
}
@media(max-width:1800px) {
.news__content {
    padding: 54px 517px;
}
}
@media(max-width:1600px) { 
    .news__content {
         padding: 54px 409px;   
    }
}
@media(max-width:1400px) { 
    .news__content {
         padding: 54px 317px;   
    }
}
@media(max-width:1100px) { 
    .news__content {
         padding: 54px 217px;   
    }
    .news {
    min-height: 85vh;
}
}
@media(max-width:900px) { 
    .news__content {
         padding: 54px 117px;   
    }
}
@media(max-width:900px) { 
    .news__content {
         padding: 54px 50px;   
    }
}
</style>