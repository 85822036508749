<template>
    <div class="stages">
        <div class="stages__content">
            <div class="stages__header">
                <p class="stages__title" 
                data-aos="flip-up" 
                data-aos-duration="800"
                >Последовательные этапы</p>
                <div class="stages__image">
                    <img src="@/assets/img/about/line2.svg">
                </div>
            </div>
            <div class="stages__img" 
            data-aos="slide-up" 
            data-aos-duration="800"> 
                <img src="@/assets/img/stages/stages.png">
            </div>
            <div class="stages__mob">
                <img src="@/assets/img/stages/mobStages.png">
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.stages {
    position: relative;
    background: #F2F6FA;
}
.stages__content {
    padding: 101px 243px;
}
.stages__header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.stages__title {
    font-weight: 600;
    font-size: 46px;
    color: #2C3136;
}
.stages__image {
    margin-top: 30px;
}
.stages__img {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}
.stages__mob {
    margin-top: 80px;
    display: none;
}
@media (max-width:1200px) {
.stages__content {
    padding: 101px 123px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.stages__img {
    display: none;
}
.stages__mob {
    display: block;
}
}
@media (max-width:600px) {
.stages__title {
    font-size: 36px;
}
.stages__content {
    padding: 101px 50px;
}
.stages__mob img {
    width: 450px;
}
}
@media (max-width:472px) {
.stages__mob img {
    width: 350px;
}
}
@media (max-width:420px) {
.stages__title {
    font-size: 20px;
}
}
@media (max-width:390px) {
.stages__mob img {
    width: 300px;
}
}
</style>