<template>
  <div class="reviews">
    <div class="reviews__content">
        <div class="reviews__header">
            <p class="reviews__title"
            data-aos="flip-up" 
            data-aos-duration="800"
            >Отзывы наших клиентов:</p>
            <div class="reviews__image">
                <img src="@/assets/img/about/line2.svg">
            </div>
        </div>
        <div ref="reviews" class="reviews__slider swiper">
            <div class="swiper-wrapper">
                <div class="swiper-slide reviews__slide" 
                v-for="list in reviewsList"
                :key="list.name"
                data-aos="flip-down" 
                data-aos-duration="800"
                >
                <div class="reviews__menu">
                    <p class="reviews__des">{{ list.review }}</p>
                    <div class="reviews__info">
                        <div class="reviews__img">
                            <img :src="list.photo">
                        </div>
                        <div class="reviews__line">
                            <img src="@/assets/img/reviews/line.svg">
                        </div>
                        <div class="reviews__list">
                            <p class="reviews__name">{{ list.name }}</p>
                            <p class="reviews__who">Клиент Елены Золенковой</p>
                        </div>
                    </div>
                </div>
                </div>
        
            </div>
        </div>
        <div class="reviews__prev" @click="goPrev">
            <img  :class="{ disable : count === 2 && !mobile }" src="@/assets/img/services/prev.svg">
        </div>
        <div class="reviews__next next" @click="goNext">
            <img  :class="{ disable : count === reviewsList.length && !mobile }" src="@/assets/img/services/next.svg">
        </div>
    </div> 
  </div>
</template>

<script>
import Swiper, { Navigation } from 'swiper'
import 'swiper/css'
import axios from 'axios'
export default {
    data() {
        return {
            reviewsList:[],
            count:2,
            mobile: false,
        }
    },
    methods:{
        goNext() {
            if (this.count < this.reviewsList.length) {
                this.count += 1
            }
        },
        goPrev() {
            if (this.count > 2) {
                this.count -= 1
            }
        },
        async loadReviews() {
            const response = await axios({method: 'get', url: this.$store.state.urlPage + 'api/get-reviews'})
            const list = await response.data
            this.reviewsList = list.map( el => {
                el.photo = this.$store.state.urlPage + '/api/' + el.photo.split('').splice(el.photo.indexOf(`reviews`)).join('').replace(/\\/g, "/")
                return el
            })
        }
    },
    mounted() {
        if (screen.width < 600) {
            this.mobile = true
        }
        else {
            this.mobile = false
        }
        this.loadReviews()
        new Swiper(this.$refs.reviews, {
            modules: [Navigation],
            slidesPerView: 2,
            centeredSlides: false,
            spaceBetween: 150,
            allowTouchMove: false,
            speed: 450,
            loop: false,
            navigation: {
                nextEl: '.reviews__next',
                prevEl: '.reviews__prev'
            },
            breakpoints: {
                1800: {
                    slidesPerView: 2,
                    spaceBetween: 150,
                },
                1700: {
                    slidesPerView: 2,
                    spaceBetween: 120,
                },
                1500: {
                    slidesPerView: 2,
                    spaceBetween: 100,
                },
                1250: {
                    slidesPerView: 2,
                    spaceBetween: 110,
                },
                1050: {
                    slidesPerView: 2,
                    spaceBetween: 80,
                },
                500: {
                    slidesPerView: 1,
                    spaceBetween: 50,
                },
                250: {
                    slidesPerView: 1,
                    spaceBetween: 40,
                },

            }

        })
    }
}
</script>

<style scoped>
.reviews {
    position: relative;
}
.reviews__content {
    position: relative;
    padding: 101px 243px;
}
.reviews__header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.reviews__title {
    font-weight: 600;
    font-size: 46px;
    color: #2C3136;
}
.reviews__image {
    margin-top: 30px;
}
.reviews__slider {
    position: relative;
    padding: 40px 20px 40px 20px;
}
.reviews__slide {
    background: #FFFFFF;
    box-shadow: 0px 9px 20px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    height: 307px;
}
.reviews__menu {
    padding: 24px 34px;
}
.reviews__des {
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    height: 90px;
    color: #2C3136;
}
.reviews__info {
    display: flex;
    align-items: center;
    margin-top:24px;
}
.reviews__img img {
    border-radius: 5px;
    width: 94px;
    height: 126px;
}
.reviews__line {
    margin: 0 30px;
}
.reviews__name {
    font-weight: 600;
    font-size: 16px;
    color: #4E4E4E;
}
.reviews__who {
    font-weight: 400;
    font-size: 14px;
    color: #9D9D9D;
}
.reviews__prev {
    position: absolute;
    left:8%;
    top:60%;
    transition: all 0.3s ease;
    cursor: pointer;
}
.reviews__prev:hover {
    transform: scale(0.98);
}
.reviews__next {
    position: absolute;
    right: 8%;
    top: 60%;
    transition: all 0.3s ease;
    cursor: pointer;
}
.reviews__next:hover {
    transform: scale(0.98);
}
.disable {
    opacity: 0.5;
}
@media(max-width:1700px) {
.reviews__des {
    font-size: 14px;
    line-height: 130%;
}
}
@media(max-width:1400px) {
.reviews__content {
    position: relative;
    padding: 101px 103px;
}
.reviews__menu {
    padding: 10px 20px;
}
.reviews__des {
    font-size: 14px;
    line-height: 130%;
    margin-bottom:40px ;
}
.reviews__name {
    font-size: 14px;
}
.reviews__who {
    font-size: 12px;
}
.reviews__prev {
    left:3%;
}
.reviews__next {
    right: 3%;
}
}
@media(max-width:1050px) {
.reviews__title {
    font-size: 36px;
}
.reviews__content {
    padding: 101px 203px;
}
}
@media(max-width:874px) {
.reviews__title {
    font-size: 36px;
}
.reviews__content {
    padding: 101px 150px;
}
.reviews__des {
    margin-bottom:20px ;
}
}
@media(max-width:700px) {
.reviews__title {
    font-size: 36px;
}
.reviews__content {
    padding: 101px 50px;
}
.reviews__prev {
    left:8%;
    top:60%;
    z-index: 50;
}
.reviews__next {
    right: 8%;
    top: 60%;
    z-index: 50;
}
}
@media(max-width:486px) {
.reviews__title {
    font-size: 36px;
    text-align: center;
}
.reviews__des {
    height: auto;
}
.reviews__slide {
    height: auto;
}
.reviews__content {
    padding: 101px 0px;
}
.reviews__prev {
    left:8%;
    top:85%;
    z-index: 50;
}
.reviews__next {
    right: 8%;
    top: 85%;
    z-index: 50;
}
}
@media(max-width:420px) {
.reviews__title {
    font-size: 20px;
    text-align: center;
}
}
</style>