import { createApp } from 'vue'
import App from '@/App'
import VueClickAway from 'vue3-click-away'
import router from '@/router/index'
import store from '@/store/index'
import VueWriter from "vue-writer"
import VueLazyload from 'vue-lazyload'
import AOS from 'aos'
import 'aos/dist/aos.css'

const app = createApp( App )

app.use( router )
app.use( store )
app.use( VueWriter )
app.use( VueClickAway )
app.use( VueLazyload )


app.mount( '#app' )

app.config.globalProperties.$AOS = AOS

app.mixin({
  mounted() {
    this.$nextTick(() => {
      this.$AOS.init() 
    })
  },
})