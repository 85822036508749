<template>
    <div>
        <draggable 
        :list="list" 
        @change="log"
        class="draggable_images"
        >
        <img
        v-for="(list, idx) in list"
        :key="list.id"
        :src="list.src"
        class="draggable_img"
        @click="deleteImg(idx)"
        >
        </draggable>
    </div>
</template>
<script>
import { defineComponent } from 'vue'
import { VueDraggableNext } from 'vue-draggable-next'
export default defineComponent({
    components: {
        draggable: VueDraggableNext,
    },
    props: {
        listImages: {
            type: Array
        }
    },
    data() {
        return {
            list: this.listImages,
        }
    },
    methods: {
        deleteImg(idx) {
            this.$emit('delete', this.listImages[idx],this.list)
        },
        log(event) {
            this.$emit('moved',this.list)
        },
    },
})
</script>

<style scoped>
.draggable_images {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 24px;
}
.draggable_img {
    width: 120px;
    height: 120px;
    border-radius: 5px;
    transition: all 0.3s ease;
    cursor: pointer;;
}
.draggable_img:hover {
    opacity: 0.5;
}
</style>