<template>
    <footer>
        <div class="footer__content">
            <div class="footer__logo">
                <div class="footer__img">
                    <img src="@/assets/img/logo/logo.svg">
                </div>
                <p class="footer__title">Агентство недвижимости</p>
            </div>
            <div class="footer__center">
                <div class="footer__icon" @click="goVk">
                    <img src="@/assets/footer/vk.svg">
                </div>
                <div class="footer__icon" @click="goWhatsApp">
                    <img src="@/assets/footer/whatsapp.svg">
                </div>
                <div class="footer__icon" @click="goViber">
                    <img src="@/assets/footer/viber.svg">
                </div>
            </div>
            <div class="footer__contacts">
                <p class="footer__info">Телефон: +7 (912) 714-88-01</p>
                <p class="footer__info">E-mail: zolenkova@mail.ru</p>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    methods:{
        goWhatsApp() {
            window.location.href = 'https://wa.me/79127148801'
        },
        goViber() {
            window.location.href = 'https://viber.click/79127148801'
        },
        goVk() {
            window.location.href = 'https://vk.com/zolenkova'
        }
    }
}
</script>

<style scoped>
footer {
    background: #F0F3F6;
}

.footer__content {
    padding: 50px 243px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.footer__logo {
    display: flex;
    align-items: center;
}
.footer__title {
    margin-left: 17px;
    font-weight: 400;
    font-size: 14px;
    color: #7E868E;
}
.footer__center {
    display: flex;
    align-items: center;
}
.footer__icon {
    margin: 0 11px;
    cursor: pointer;
}
.footer__info {
    margin-bottom: 10px;
    font-weight: 400;
    font-size: 12px;
    color: #7E868E;
}
@media( max-width:1532px ) {
.footer__content {
    padding: 22px 101px;
}
}
@media( max-width:1110px ) {
.footer__content {
    padding: 22px 50px;
}
}
@media( max-width:730px ) {
.footer__content {
    padding: 22px 50px;
    flex-direction: column;
}
.footer__center {
    margin: 20px 0 ;
}
}
</style>