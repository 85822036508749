<template>
  <section-create-object-main></section-create-object-main>
</template>

<script>
import SectionCreateObjectMain from '@/components/SectionCreateObject/SectionCreateObjectMain.vue'
export default {
  components: { SectionCreateObjectMain },

}
</script>

<style>

</style>