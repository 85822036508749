<template>
  <div>
    <section-news-main></section-news-main>
  </div>
</template>

<script>
import SectionNewsMain from '@/components/SectionNews/SectionNewsMain.vue';

export default {
  components: { SectionNewsMain }
}
</script>

<style scoped></style>