<template>
  <button class="myButton">
    <slot></slot>
  </button>
</template>

<script>
export default {

}
</script>

<style scoped>
.myButton {
    margin-top: 15px;
    background: #4CAF50;
    border-radius: 8px;
    font-weight: 500;
    font-size: 16px;
    border:none;
    padding: 8px 68px;
    color: #FFFFFF;
    transition: all 0.3s ease;
    cursor: pointer;
}
.myButton:hover {
    transform: scale(0.98);
}
</style>