<template>
  <div>
    <section-objects-main></section-objects-main>
  </div>
</template>

<script>
import SectionObjectsMain from '@/components/SectionObjects/SectionObjectsMain.vue'

export default {
  components: { SectionObjectsMain },

    mounted() {
        
    }
}
</script>

<style scoped>

</style>