<template>
    <div class="objectMain" v-if="imagesArray">
        <div class="objectMain__content">
            <div class="objectMain__about">
                <div>
                    <object-item
                    v-if="imagesArray"
                    :imagesArray="imagesArray"
                    :currentIndex="currentIndex"
                    ></object-item>
                </div>
            </div>
            <div class="objectMain__info">
                <div class="objectMain__menu">
                    <p class="objectMain__money">{{ price }} ₽</p>
                    <p class="objectMain__des">Продается {{ infoObject.name }}</p>
                    <div class="objectMain__logo">
                        <div class="objectMain__icon">
                            <img src="@/assets/img/logo/logo.svg">
                        </div>
                        <p class="objectMain__name">{{ infoObject.surname }}</p>
                    </div>
                    <div class="objectMain__block">
                        <button class="objectMain__btn" @click="callPhone">Позвонить</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="objectMain__list">
            <div class="objectMain__photos">
                <div 
                v-for="(img, idx) in imagesArray"
                :key="img.src"
                class="objectMain__img"
                :class="{ activeImage: idx === $store.state.currentIdxSlider }"
                >
                <!-- <img 
                @click="setImage(idx)" 
                :src="img.src"
                > -->
                <img 
                @click="setImage(idx)" 
                v-lazy="img.src"
                >
                </div>
            </div>
            <div class="objectMain__text">
                <p class="objectMain__tag">Описание:</p>
                <p class="objectMain__type">{{ infoObject.des }}</p>
            </div>
        </div>
    </div>
    <p v-if="objectError === true" class="objectMain__error">Объект не найден</p>
</template>

<script>
import axios from 'axios'
import ObjectItem from '../SectionObject/Item/ObjectItem.vue'
export default {
  data() {
    return {
        imagesArray: null,
        currentIndex:0,
        objectError:false,
        infoObject:'',
        price:''
    }
  },
  methods:{
    setImage( idx ) {
        this.$store.state.currentIdxSlider = idx
        this.$store.state.startIdxSlider = idx
        this.$store.state.endIdxSlider = idx + 1
        this.currentIndex = idx
    },
    callPhone() {
        window.location.href = 'tel:+79127148801'
    },
    async getOneObject() {
        try {
            const response = await axios.post(this.$store.state.urlPage + 'api/get-object', {
            codename: this.$route.params.id
            })
            const newArr = await JSON.parse(response.data.photos)
            this.imagesArray = newArr.map( el => {
                el.src = this.$store.state.urlPage + '/api/' + el.src.split('').splice(el.src.indexOf(`objects`)).join('').replace(/\\/g, "/")
                return el
            })
            this.infoObject = await response.data
            if( this.infoObject ) {
                this.setPrice( this.infoObject.price )
            }
            this.objectError = false
        }
        catch(e) {
            this.objectError = true
        }
    
    },
    setPrice( money ) {
    if (money.length === 4) {
                let res = money.split('')
                res.splice(1, 0, ' ');
                this.price = res.join('')
    }
    else if (money.length === 5) {
                let res = money.split('')
                res.splice(2, 0, ' ');
                this.price = res.join('')
    }
    else if (money.length === 6) {
                let res = money.split('')
                res.splice(3, 0, ' ');
                this.price = res.join('')
    }
    else if (money.length === 7) {
                let res = money.split('')
                res.splice(1, 0, ' ');
                res.splice(5, 0, ' ');
                this.price = res.join('')
    }
    else if (money.length === 8) {
                let res = money.split('')
                res.splice(2, 0, ' ');
                res.splice(6, 0, ' ');
                this.price = res.join('')
    }
    else {
                this.price = money
    }
    }
  },
  created() {
    this.getOneObject()
  },
  mounted() {
    this.currentIndex = 0
  },
  components: { ObjectItem },
}
</script>

<style scoped>

.objectMain__content {
    display: flex;
    justify-content: space-between;
    padding: 41px 243px;
}
.objectMain__info {
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    width: 453px;
}
.objectMain__menu {
    padding: 28px 28px;
}
.objectMain__money {
    font-weight: 600;
    font-size: 30px;
    color: #2C3136;
}
.objectMain__des {
    margin-top: 7px;
    font-weight: 400;
    font-size: 16px;
    color: #767676;
}
.objectMain__logo {
    display: flex;
    align-items: center;
    margin-top:36px;
}
.objectMain__name {
    margin-left: 22px;
    font-weight: 600;
    font-size: 18px;
    color: #2C3136;
}
.objectMain__block {
    margin-top: 35px;
}
.objectMain__btn {
    background: #D7593C;
    border-radius: 5px;
    padding:20px 0;
    border:none;
    font-weight: 500;
    font-size: 16px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    transition: all 0.3s ease;
    cursor: pointer;
}
.objectMain__btn:hover {
    transform: scale(0.98);
}
.objectMain__list {
    padding: 0px 243px;
}
.objectMain__photos {
    display: grid;
    grid-template-columns: repeat(11, 1fr);
    grid-gap: 10px;
    max-width: 926px;
}
.objectMain__about {
    margin-right: 30px;
}
.objectMain__img {
    border: 2px solid #F2F2F2;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
}
.objectMain__img img {
    width: 68px;
    height: 55px;
    border-radius: 5px;
    cursor: pointer;
}
.objectMain__text {
     max-width: 926px;
     margin-top: 44px;
     margin-bottom: 60px;
}
.objectMain__tag {
    font-weight: 600;
    font-size: 30px;
    color: #2C3136;
}
.objectMain__type {
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    margin-top: 16px;
    max-width: 923px;
    word-break:break-all;
    color: #767676;
}
.activeImage {
    border: 2px solid rgba(215, 89, 60, 0.8);
}
.objectMain__error {
    font-weight: 500;
    font-size: 71px;
    margin-top: 180px;
    text-align: center;
    margin-bottom: 360px;
}
@media (max-width:1800px) {
.objectMain__content {
    padding: 41px 103px;
}
.objectMain__list {
    padding: 0px 103px;
}
}
@media (max-width:1400px) {
.objectMain__content {
    padding: 41px 50px;
}
.objectMain__list {
    padding: 0px 50px;
}
}
@media (max-width:1000px) {
.objectMain__photos {
    grid-template-columns: repeat(7, 1fr);
}
}
@media (max-width:900px) {
.objectMain__content {
    padding: 41px 50px;
}
.objectMain__about {
    margin-right: 0px;
}
.objectMain__list {
    padding: 0px 50px;
}
.objectMain__content {
    flex-direction: column;
}
.objectMain__info {
    margin-top: 30px;
    width: 100%;
}
}
@media (max-width:700px) {
.objectMain__photos {
    grid-template-columns: repeat(4, 1fr);
}
}
@media(max-width:400px) {
    .objectMain__name {
        font-size: 15px;
    }
    .objectMain__photos {
    grid-template-columns: repeat(2, 1fr);
}
}
</style>