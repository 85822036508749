<template>
  <div class="createPost">
    <div class="createPost__content">
      <p class="createPost__title">Пост</p>
      <div class="createPost__block">
        <label class="createPost__label">Дата:</label>
        <input
          class="createPost__input"
          type="text"
          placeholder="Пример:01.10.2000"
          v-model="date"
        />
      </div>
      <div class="createPost__block">
        <label class="createPost__label">Название:</label>
        <input
          class="createPost__input"
          type="text"
          placeholder="Пример:ВТБ БАНК"
          v-model="name"
        />
      </div>
      <div class="createPost__block">
        <label class="createPost__label">Описание Поста:</label>
        <textarea
          class="createPost__area"
          type="text"
          placeholder="Пример:Продам однокомнатную уютную, светлую и очень теплую квартиру в ЖК..."
          v-model="des"
        ></textarea>
      </div>
      <div class="createPost__photo">
        <div class="createPost__left">
          <p class="createPost__name">Фотографии:</p>
          <p class="createPost__des">Только 1 фото</p>
          <input class="createPost__file" type="file" @change="onFileChange" />
        </div>
      </div>
      <button class="createPost__btn" @click="upload">Разместить</button>
    </div>
    <div class="createPost__image" v-if="imageSrc !== ''">
      <img class="createPost__img" :src="imageSrc" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      fileImage: null,
      imageSrc: "",
      date: "",
      name: "",
      des: "",
    };
  },
  methods: {
    onFileChange(e) {
      const file = e.target.files[0];
      this.imageSrc = URL.createObjectURL(file);
      this.fileImage = e.target.files[0];
    },
    async upload() {
      if (
        this.imageSrc !== "" &&
        this.date !== "" &&
        this.name !== "" &&
        this.des !== ""
      ) {
        const response = await axios.post(
          this.$store.state.urlPage + "api/post-create",
          {
            date: this.date,
            name: this.name,
            des: this.des,
            photo: this.fileImage,
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        if (response) {
          alert(
            "Ваш пост был успешно размещен, после нажатия ОК вас переведет на другую страницу"
          );
          this.$router.push("/admin/panel");
        }
      } else {
        alert("Заполните пустые поля и добавьте фотографию");
      }
    },
  },
};
</script>

<style scoped>
.createPost {
  display: flex;
}
.createPost__content {
  padding: 43px 243px;
}
.createPost__title {
  font-weight: 700;
  font-size: 48px;
  margin-bottom: 21px;
  color: #2c3136;
}
.createPost__block {
  display: flex;
  align-items: center;
  margin-bottom: 11px;
}
.createPost__label {
  font-weight: 500;
  font-size: 16px;
  color: #2c3136;
}
.createPost__input {
  border: 1px solid #2c3136;
  border-radius: 5px;
  background: white;
  padding: 15px;
  outline: none;
  min-width: 350px;
  margin-left: 15px;
  font-weight: 500;
  font-size: 14px;
  color: #2c3136;
  transition: all 0.3s ease;
}
.createPost__input:focus {
  border: 1px solid #d7593c;
}
.createPost__area {
  border: 1px solid #2c3136;
  border-radius: 5px;
  background: white;
  padding: 15px;
  outline: none;
  min-width: 350px;
  margin-left: 15px;
  font-weight: 500;
  font-size: 14px;
  color: #2c3136;
}
.createPost__photo {
  display: flex;
  margin-top: 32px;
}
.createPost__name {
  font-weight: 600;
  font-size: 18px;
  color: #2c3136;
}
.createPost__des {
  font-weight: 500;
  font-size: 14px;
  margin: 3px 0;
  color: #868686;
}
.createPost__file {
  margin-top: 20px;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
}
.createPost__img {
  border-radius: 10px;
  padding: 43px 0;
  max-width: 650px;
}
.createPost__btn {
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  background: #4caf50;
  border-radius: 5px;
  border: none;
  margin-top: 29px;
  padding: 15px 56px;
  transition: all 0.3s ease;
  cursor: pointer;
}
.createPost__btn:hover {
  transform: scale(0.98);
}
</style>
