<template>
    <SectionVxodMain></SectionVxodMain>
</template>

<script>
import SectionVxodMain from '@/components/SectionVxod/SectionVxodMain.vue'
export default {
    components:{ SectionVxodMain }
}
</script>

<style>

</style>