<template>
  <div class="main">
    <section-main-banner></section-main-banner>
        <section-main-about></section-main-about>
        <section-main-services></section-main-services>
        <section-main-stages></section-main-stages>
        <section-main-reviews></section-main-reviews>
        <section-main-contacts></section-main-contacts>
  </div>
</template>

<script>

import SectionMainBanner from '@/components/SectionMain/SectionMainBanner'
import SectionMainAbout from '@/components/SectionMain/SectionMainAbout'
import SectionMainServices from '@/components/SectionMain/SectionMainServices'
import SectionMainStages from '@/components/SectionMain/SectionMainStages'
import SectionMainReviews from '@/components/SectionMain/SectionMainReviews'
import SectionMainContacts from '@/components/SectionMain/SectionMainContacts'
import SectionLoading from '@/components/SectionLoading/SectionLoading.vue'
import axios from 'axios'

export default {
  data() {
    return { 
      isLoading:false
    };
  },
  methods:{
    async getAxios() {
    }
  },
  mounted(){
    this.getAxios()
  },
  components: { SectionMainBanner, SectionMainAbout, SectionMainServices, SectionMainStages, SectionMainReviews, SectionMainContacts, SectionLoading,},
}
</script>

<style scoped>
</style>