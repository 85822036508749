import Main from '@/pages/Main'
import Objects from '@/pages/Objects'
import Object from '@/pages/Object'
import News from '@/pages/News'
import About from '@/pages/About'
import Vxod from '@/pages/Vxod'
import Panel from '@/pages/Panel'
import CreateObject from '@/pages/CreateObject'
import CreatePost from '@/pages/CreatePost'
import CreateReview from '@/pages/CreateReview'
import Section404 from '@/components/Section404/Section404'

import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path:'/',
        component:Main,
        name:"/main"
    },
    {
        path:'/objects-kv',
        component:Objects,
        name:"/objects-kv",

    },
    {
        path:'/objects-kv/:id',
        component:Object,
        name:'/object',
    },
    {
        path:'/news',
        component:News,
        name:'/news'
    },
    {
        path:'/about',
        component:About,
        name:'/about'
    },
    {
        path:'/admin/sign-in',
        component:Vxod,
        name:'admin/sign-in',
        beforeEnter( to, from, next ) {
            if( !localStorage.getItem('token') ) {
                next()
            }
            else {
                next('/admin/panel')
            }
        }
    },
    {
        path:'/admin/panel',
        component:Panel,
        name:'admin/panel',
        beforeEnter( to, from, next ) {
            if( localStorage.getItem('token') ) {
                next()
            }
            else {
                next('/admin/sign-in')
            }
        }
    },
    {
        path:'/admin/create-object',
        component:CreateObject,
        name:'admin/create-object',
        beforeEnter( to, from, next ) {
            if( localStorage.getItem('token') ) {
                next()
            }
            else {
                next('/admin/sign-in')
            }
        }
    },
    {
        path:'/admin/create-post',
        component:CreatePost,
        name:'admin/create-post',
        beforeEnter( to, from, next ) {
            if( localStorage.getItem('token') ) {
                next()
            }
            else {
                next('/admin/sign-in')
            }
        }
    },
    {
        path:'/admin/create-review',
        component:CreateReview,
        name:'admin/create-review',
        beforeEnter( to, from, next ) {
            if( localStorage.getItem('token') ) {
                next()
            }
            else {
                next('/admin/sign-in')
            }
        }
    },
    {
        path:'/:pathMatch(.*)*',
        component:Section404,
    },

]

const router = createRouter({
    routes,
    history:createWebHistory()
})
export default router
