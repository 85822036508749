<template>
  <section-create-review-main></section-create-review-main>
</template>

<script>
import SectionCreateReviewMain from '@/components/SectionCreateReview/SectionCreateReviewMain.vue'
export default {
  components: { SectionCreateReviewMain },

}
</script>

<style scoped>

</style>