<template>
  <section-about-main></section-about-main>
</template>

<script>
import SectionAboutMain from '@/components/SectionAbout/SectionAboutMain.vue'
export default {
  components: { SectionAboutMain },

}
</script>

<style scoped>

</style>