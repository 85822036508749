<template>
    <div class="services">
        <div class="services__content">
            <p class="services__title" data-aos="flip-up" data-aos-duration="800">Предоставляемые нами услуги:</p>
            <div class="services__image">
                <img src="@/assets/img/about/line2.svg">
            </div>
            <div ref="swiper" class="services__slider swiper">
                <div class="swiper-wrapper">
                    <div class="swiper-slide services__slide" 
                    v-for="list in swiperList" 
                    :key="list.name"
                    data-aos="flip-down" 
                    data-aos-duration="800"
                    >
                        <img class="services__img" src="@/assets/img/services/back.svg">
                        <img class="services__icon" :src="list.src">
                        <p class="services__name">{{ list.name }}</p>
                        <p class="services__info">{{ list.info }}</p>
                    </div>
                </div>
            </div>
            <div class="swiper-prev" @click="goPrev">
                    <img :class="{ disable: count === 3 && !mobile }" src="@/assets/img/services/prev.svg">
                    </div>
                <div class="swiper-next" @click="goNext">
                    <img :class="{ disable: count === swiperList.length && !mobile}" src="@/assets/img/services/next.svg">
            </div>
        </div>
    </div>
</template>
<script>
import Swiper, { Navigation } from 'swiper'
import 'swiper/css'

export default {
    data() {
        return {
            swiperList: [
                {
                    name: 'Купля-Продажа',
                    src: './services/1.png',
                    info: `Услуга, позволяющая клиентам покупать или продавать недвижимость, включая жилые или коммерческие здания, земельные участки и т.д.`,
                },
                {
                    name: 'Оценка вашей недвижимости',
                    src: './services/2.png',
                    info: ` Услуга, предоставляющая клиентам профессиональную оценку стоимости их недвижимости.`,
                },
                {
                    name: 'Ипотека',
                    src: './services/3.png',
                    info: `Финансовый инструмент, позволяющий клиентам приобрести недвижимость с помощью кредита.`,
                },
                {
                    name: 'Военная ипотека',
                    src: './services/4.png',
                    info: `Специальный вид ипотеки, предназначенный для военных или ветеранов.`,
                },
                {
                    name: 'Материнский капитал',
                    src: './services/5.png',
                    info: `Государственная программа, предоставляющая финансовую помощь молодым семьям для приобретения жилья.`,
                },
            ],
            count:3,
            mobile:false,
        }
    },
    methods:{
        goNext() {
            if( this.count < this.swiperList.length ) {
                this.count += 1
            }
        },
        goPrev() {
            if( this.count > 3 ) {
                this.count -= 1
            }
        },
    },
    mounted() {
        if( screen.width < 600 ) {
            this.mobile = true
        }
        else {
            this.mobile = false
        }
        new Swiper(this.$refs.swiper, {
            modules: [Navigation],
            slidesPerView: 3,
            centeredSlides: false,
            spaceBetween: 200,
            allowTouchMove: false,
            speed: 450,
            loop: false,
            navigation: {
                nextEl: '.swiper-next',
                prevEl: '.swiper-prev'
            },
            breakpoints: {
                1800: {
                    slidesPerView: 3,
                    spaceBetween: 200,
                },
                1700: {
                    slidesPerView: 3,
                    spaceBetween: 150,
                },
                1500: {
                    slidesPerView: 3,
                    spaceBetween: 130,
                },
                1200: {
                    slidesPerView: 3,
                    spaceBetween: 50,
                },
                1000:{
                    slidesPerView: 2,
                    spaceBetween: 200,
                },
                600: {
                    slidesPerView: 2,
                    spaceBetween: 50,
                },
                500: {
                    slidesPerView: 1,
                    spaceBetween: 120,
                },
                200: {
                    slidesPerView: 1,
                    spaceBetween: 50,
                },
            }
        })
        
    },

};
</script>
<style scoped>
.services {
    padding: 80px 243px;
    overflow: hidden;
}
.services__content {
    position: relative;
}
.services__title {
    text-align: center;
    font-weight: 600;
    font-size: 46px;
    color: #2C3136;
}
.services__image {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 0 0 0;
}
.services__slider {
    position: relative;
    padding: 40px 20px 40px 20px;
}
.services__slide {
    height: 337px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    text-align: center;
    position: relative;
}
.services__img {
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}
.services__icon {
    position: relative;
    margin-top: 34px;
    z-index: 2;
}
.services__name {
    font-weight: 700;
    font-size: 20px;
    color: #2C3136;
}
.services__info {
    font-weight: 500;
    font-size: 14px;
    line-height: 133%;
    margin-top: 11px;
    padding: 0 10px;
    color: #2C3136;
}
.services__content .swiper-prev {
    position: absolute;
    top:62%;
    left:-5%;
    transition: all 0.3s ease;
    cursor: pointer;
}
.services__content .swiper-prev:hover {
    transform: scale(0.98);
}
.services__content .swiper-next {
    position: absolute;
    top: 62%;
    right: -5%;
    transition: all 0.3s ease;
    cursor: pointer;
}
.services__content .swiper-next:hover {
    transform: scale(0.98);
}
.disable {
    opacity: 0.5;
}
@media (max-width:1700px) {
  .services {
    padding: 80px 183px;
    overflow: hidden;
}
}
@media (max-width:1450px) {
  .services {
    padding: 80px 123px;
    overflow: hidden;
}
.services__name {
    font-size: 16px;
}
.services__info {
    font-size: 14px;
}
.services__icon  {
    width: 100px;
}
}

@media (max-width:1250px) {
.services__content .swiper-prev {
    left:-10%;
}
.services__content .swiper-next {
    right: -10%;
}
}
@media (max-width:900px) {
    .services {
        padding: 43px 50px;
    }
.services__title {
    font-size: 36px;
}
.services__slider {
    position: relative;
    padding: 20px;
}
}
@media (max-width:884px) {
.services__content .swiper-prev {
    left:0%;
    top:60%;
    z-index: 5;
}
.services__content .swiper-next {
    right: 0%;
     top:60%;
    z-index: 5;
}
}
@media (max-width:420px) {

    .services__title {
        font-size: 20px;
    }
}
</style>
