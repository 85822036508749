<template>
  <div class="blockPosts">
    <MyButton @click="$router.push('/admin/create-post')">Создать пост</MyButton>
    <h2 class="blockPosts__empty" v-if="postsList.length <= 0">Пусто</h2>
    <div class="blockPosts__items">
            <posts-item
            v-for="post in arrayPosts"
            :key="post.id"
            :post="post"
            @accessDeletePost="DeletePost"
            ></posts-item>
    </div>
    <div class="blockPosts__pagination" v-if="postsList.length > 0">
        <pagination
        :sortedSearchArray="postsList"
        :startIdx="startIdx"
        :endIdx="endIdx"
        :maxIndexProps="maxIdx"
        @IndexNext="goNext"
        @IndexPrev="goPrev"
        @IndexBtns="goBtns"
        ></pagination>
    </div>
  </div>
</template>

<script>
import MyButton from '@/components/SectionPanel/UI/my-button.vue'
import PostsItem from  '@/components/SectionPanel/Posts/PostsItem/PostsItem.vue'
import Pagination from '@/components/Logic/Pagination.vue'
import axios from 'axios'
export default {
    data() {
        return {
            postsList:[],
            startIdx: 0,
            endIdx: 4,
            maxIdx: 4,
        }
    },
    methods: {
        goNext(startIndex, endIndex) {
            this.startIdx = startIndex
            this.endIdx = endIndex
        },
        goPrev(startIndex, endIndex) {
            this.startIdx = startIndex
            this.endIdx = endIndex
        },
        goBtns(startIndex, endIndex) {
            this.startIdx = startIndex
            this.endIdx = endIndex
        },
        async loadPosts() {
            const response = await axios.get(this.$store.state.urlPage + 'api/get-posts',{
                headers:{
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            })
            const list = await response.data
            this.postsList = list
        },
        DeletePost( name ) {
            if( this.postsList.slice(this.startIdx, this.endIdx).length === 1 && this.startIdx !== 0 ) {
                this.postsList.splice(this.postsList.findIndex(el => el.name === name), 1)
                this.startIdx -= 4
                this.endIdx -= 4
                this.$store.state.currentIdxObjects -= 1
            }
            else {
                this.postsList.splice(this.postsList.findIndex(el => el.name === name), 1)
            }
        }
    },
    computed: {
        arrayPosts() {
            return this.postsList.slice(this.startIdx, this.endIdx)
        }
    },
    mounted(){
        this.$store.state.currentIdxObjects = 1
        this.loadPosts()
    },
    components:{ MyButton, PostsItem, Pagination }
}
</script>

<style scoped>
.blockPosts {
    margin-top: 50px;
}
.blockPosts__empty {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    margin-top: 120px;
    color: #2C3136;
}
.blockPosts__items {
    height: 425px;
    margin-top: 20px;
}
.blockPosts__pagination {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
}
</style>