<template>
  <div class="newsItem">
    <div class="newsItem__content">
        <div class="newsItem__header">
                <div class="newsItem__ava">
                    <img src="@/assets/img/news/ava.png">
                </div>
                <div class="newsItem__block">
                    <div class="newsItem__text">
                        <p class="newsItem__agentstvo">Агентство недвижимости “Золенкова”</p>
                        <p class="newsItem__by">от Елены Золенковой</p>
                    </div>
                  <p class="newsItem__date">{{ news.date }}</p>
            </div>
        </div>
        <div class="newsItem__info">
            <p class="newsItem__name">{{ news.name }}</p>
            <p class="newsItem__des">{{ news.des }}</p>
            <div class="newsItem__img" v-if="imgURL !== ''">
                <img v-lazy="imgURL">
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    props:{
        news:{
            type:Object
        }
    },
    data() {
        return {
            imgURL: '',
        }
    },
    mounted() {
        this.imgURL = this.$store.state.urlPage + '/api/' + this.news.photo.split('').splice(this.news.photo.indexOf(`posts`)).join('').replace(/\\/g, "/")
    }
}
</script>

<style scoped>
.newsItem {
    background: #FFFFFF;
    box-shadow: 0px 9px 20px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    margin-bottom: 60px;
}
.newsItem__content {
    padding: 37px 35px;
}
.newsItem__header {
    display: flex;
    align-items: center;
}
.newsItem__block {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.newsItem__text {
    margin-left: 15px;
}
.newsItem__agentstvo {
    font-weight: 600;
    font-size: 16px;
    color: #2C3136;
}
.newsItem__by {
    font-weight: 500;
    font-size: 14px;
    color: #656565;
}
.newsItem__date {
    font-weight: 500;
    font-size: 14px;
    color: #656565;
}
.newsItem__info {
    margin-top: 20px;
}
.newsItem__name {
    font-weight: 700;
    font-size: 24px;
    color: #2C3136;
}
.newsItem__des {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    margin-top: 5px;
    color: #2C3136;
}
.newsItem__img {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 33px;
}
.newsItem__img img {
    border-radius: 10px;
    max-width: 100%;
    max-height: 400px;
}
@media(max-width:800px) {
.newsItem__agentstvo {
    font-weight: 600;
    font-size:1.75vw;
    color: #2C3136;
}
.newsItem__by {
    font-weight: 500;
    font-size: 1.50vw;
    color: #656565;
}
.newsItem__date {
    font-weight: 500;
    font-size: 1.50vw;
    color: #656565;
}
.newsItem__info {
    margin-top: 18px;
}
.newsItem__name {
    font-weight: 700;
    font-size: 2.50vw;
    color: #2C3136;
}
}

</style>