<template>
  <div class="desktop">
    <header>
        <div class="header__content">
            <div class="header__logo" @click="goMain">
                <img src="../assets/img/logo/logo.svg">
            </div>
            <div class="header__center" v-if="adminName === ''">
                <div class="header__email">
                    <div class="header__icon">
                        <img src="../assets/img/navbar/email.svg">
                    </div>
                    <p class="header__des">zolenkova@mail.ru</p>
                </div>
                <div class="header__location">
                    <div class="header__icon">
                        <img src="../assets/img/navbar/email.svg">
                    </div>
                    <p class="header__des">г. Киров, ул. Свободы<br>д. 131 , оф. 2</p>
                </div>
            </div>
            <div class="header__call" v-if="adminName === ''" >
                <div class="header__phone" @click="callPhone">
                    <svg class="header__svg" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.4333 8.63341C10.9634 8.16957 10.3769 8.16957 9.91005 8.63341C9.55393 8.98652 9.19782 9.33963 8.84769 9.69873C8.75193 9.79748 8.67113 9.81843 8.55442 9.75259C8.32399 9.62691 8.0786 9.52516 7.85715 9.38751C6.82472 8.73814 5.95987 7.90324 5.19377 6.9636C4.81372 6.49678 4.47556 5.99703 4.23915 5.43445C4.19127 5.32074 4.20025 5.24592 4.29301 5.15316C4.64913 4.80902 4.99627 4.45591 5.3464 4.1028C5.83418 3.61203 5.83418 3.03748 5.3434 2.54372C5.06509 2.26242 4.78679 1.98712 4.50848 1.70582C4.22119 1.41855 3.9369 1.12828 3.64662 0.84399C3.17679 0.386141 2.59025 0.386141 2.12341 0.846983C1.7643 1.20009 1.42016 1.56218 1.05507 1.90931C0.716908 2.22951 0.546333 2.62152 0.510422 3.07937C0.453563 3.8245 0.636109 4.52773 0.893469 5.21301C1.42016 6.63144 2.22217 7.89127 3.19475 9.04637C4.50848 10.6084 6.07658 11.8443 7.91102 12.7361C8.73696 13.1371 9.59283 13.4453 10.5235 13.4962C11.1639 13.5321 11.7205 13.3705 12.1664 12.8708C12.4717 12.5296 12.8158 12.2184 13.139 11.8922C13.6178 11.4074 13.6208 10.8209 13.145 10.3421C12.5764 9.77055 12.0048 9.20198 11.4333 8.63341Z" fill="white"/>
                    </svg>
                </div>
                <div class="header__info">
                    <p class="header__number">+7 (912) 714-88-01</p>
                    <p class="header__schedule">Пн-пт с 9:00 до 20:00<br>
                    Сб-вс: Выходной</p>
                </div>
            </div>
            <button 
            class="header__logout"
            v-if="deleteBtn === true && adminName !== ''" 
            @click="deleteLocal"
            >Выйти</button>
        </div>
        <nav class="header__menu" :class="{ active: currentPath !== '/' }" v-if="adminName === ''">
            <ul class="header__list">
                <li 
                v-for="link in listLinks" 
                :key="link.title" 
                :class="{ activeNav: currentPath === link.path }"
                @click="$router.push(link.path)"
                >
                {{ link.title }}
                </li>
            </ul>
        </nav>
      </header>
  </div>
  <div class="mobile">
    <header>
        <div class="mobHeader__content">
            <div class="mobHeader__logo">
                <img src="@/assets/img/logo/logo.svg">
            </div>
            <div class="mobHeader__burger" @click="wrapWindow = true">
                <img src="@/assets/img/navbar/open.svg">
            </div>
        </div>
    </header>
    <WrapHeader
    @close="wrapWindow = false"
    :class="{ activeWrap : wrapWindow === true }"
    ></WrapHeader>
  </div>
</template>

<script>
import WrapHeader from '@/components/WrapHeader.vue'
export default {
    props:{
        namePath:{},
        mobBlock:false,
    },
    data() {
        return {
            currentPath:'',
            pathId:'',
            listLinks:[
                { path:'/', title:'Главная' },
                { path: '/objects-kv', title: 'Объекты' },
                { path: '/news', title: 'Новости' },
                { path: '/about', title: 'О нас' },
            ],
            adminName: '',
            deleteBtn:false,
            wrapWindow:false,
        }
    },
    methods:{
        goMain() {
            this.$router.push('/')
            this.adminName = ''
        },
        deleteLocal() {
            localStorage.clear()
            window.location.reload()
        },
        callPhone() {
            window.location.href = 'tel:+79127148801'
        }
    },
    watch:{
        $route( value ) {
            if( this.namePath === '/object' ) {
                this.currentPath = '/objects-kv'
            }
            else if( this.namePath === '/main' ) {
                this.currentPath = '/'
            }
            else {
                this.currentPath = value.name
            }
        }  
    },
    mounted() {
       if( this.namePath === '/object' ) {
          this.currentPath = '/objects-kv'
       }
       else if (this.namePath === '/main') {
            this.currentPath = '/'
        }
        else {
            this.currentPath = this.namePath
        }
        if( this.namePath[0] === 'a' ) {
            this.adminName = this.namePath
        }
        else {
            this.adminName = ''
        }
        if( localStorage.getItem('token') && this.adminName !== '' ) {
            this.deleteBtn = true
        }
        else {
            this.deleteBtn = false
        }
    },
    components:{WrapHeader}
}
</script>

<style scoped>
header {
    position: relative;
    width: 100%;
    z-index: 2;
}
.header__content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 42px 243px;
    border-bottom: 1px solid #DFDFDF;
    background: white;
}
.header__logo {
    cursor: pointer;
}
.header__center {
    display: flex;
    align-items: center;
    margin-left: 70px;
}
.header__email {
    display: flex;
    align-items: center;
}
.header__icon img {
    display: flex;
    align-items: center;
    margin-right: 12px;
}
.header__des {
    font-weight: 500;
    font-size: 14px;
    color: #2C3136;
}
.header__location {
    display: flex;
    align-items: center;
    margin-left: 39px;
}
.header__call {
    display: flex;
    align-items: flex-start;
}
.header__phone  {
    width: 36px;
    height: 36px;
    border-radius: 100%;
    background: #D7593C;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    transition: all 0.3s ease;
    cursor: pointer;
}
.header__svg {
    animation: ring 3s infinite ease-in-out;
    transition: all 0.3s ease;
}
@keyframes ring {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(20deg);
  }
  20% {
    transform: rotate(-20deg);
  }
  30% {
    transform: rotate(15deg);
  }
  40% {
    transform: rotate(-15deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(-10deg);
  }
  70% {
    transform: rotate(5deg);
  }
  80% {
    transform: rotate(-5deg);
  }
  90% {
    transform: rotate(2deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.header__phone:hover .header__svg path{
    fill:#D7593C;
}
.header__phone:hover {
    background: none;
    border:1px solid #D7593C;
}
.header__number {
    font-weight: 600;
    font-size: 18px;
    color: #2C3136;
}
.header__schedule {
    font-weight: 400;
    font-size: 14px;
    margin-top: 2px;
    color: #2C3136;
}
.header__menu {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.active {
    box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.25);
    z-index: -1;
}
.header__list {
    display: flex;
    align-items: center;
}
.header__list li {
    list-style-type: none;
    padding: 14px 25px;
    font-weight: 500;
    font-size: 14px;
    color: #2C3136;
    transition: all 0.3s ease;
    cursor: pointer;
}
.header__list li:hover {
    background: #D7593C;
    color:white;
}
.header__list li.activeNav {
    background: #D7593C;
    color: white;
}
.header__logout {
    padding: 13px 30px;
    border:none;
    background: red;
    color:white;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    transition: all 0.3s ease;
    cursor: pointer;
}
.header__logout:hover {
    transform: scale(0.98);
}
.mobile {
    width: 100%;
    display: none;
}
.mobHeader__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 50px;
}
.activeWrap {
    top: 0%;
}

@media (max-width:1532px) {
.header__number {
    font-size: 16px;
}
.header__schedule {
    font-size: 12px;
}
.header__center {
    margin-left: 30px;
}
.header__content {
    padding: 22px 101px;
}
}
@media ( max-width:1000px ) {
.header__center {
    margin-left: 0px;
}
.header__content {
    padding: 22px 50px;
}
}
@media ( max-width:790px ) {
    .desktop {
        display: none;
    }
    .mobile{
        display: block;
    }
}
</style>