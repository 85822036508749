<template>
    <button class="delete">Удалить</button>
</template>

<script>
export default {

}
</script>

<style scoped>  
.delete {
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
    background: #FF0000;
    border-radius: 8px;
    border:none;
    padding: 8px 31px;
    transition: all 0.3s ease;
    cursor: pointer;
}
.delete:hover {
    transform: scale(0.98);
}
</style>