<template>
  <div class="createReview">
    <div class="createReview__content">
        <p class="createReview__title">Отзыв</p>
        <div class="createReview__block">
            <label class="createReview__label">Имя клиентa/ов:</label>
            <input class="createReview__input" type="text" placeholder="Пример: Максим и Марина" v-model="name">
        </div>
        <div class="createReview__block">
            <label class="createReview__label">Отзыв клиента/ов:</label>
            <textarea 
            class="createReview__area" 
            type="text" 
            placeholder="Пример: Продам однокомнатную уютную, светлую и очень теплую квартиру в ЖК "
            v-model="review"
            ></textarea>
        </div>
        <div class="createReview__photo">
            <div class="createReview__download">
                <p class="createReview__name">Фотографии:</p>
                <p class="createReview__des">Только 1 фотография</p>
                <input class="createReview__file" type="file" @change="imageUrl">
            </div>
        </div>
        <button class="createReview__btn" @click="upload">Разместить</button>
    </div>
    <div class="createReview__image" v-if="imageSrc !== ''">
        <img class="createReview__img" :src="imageSrc">
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
    data() {
        return {
            fileImage:null,
            imageSrc: '',
            name:'',
            review:''
        }
    },
    methods: {
        imageUrl(e) {
            const file = e.target.files[0]
            this.imageSrc = URL.createObjectURL(file)
            this.fileImage = e.target.files[0]
        },
        async upload() {
            if( this.imageSrc !== '' && this.name !== '' && this.review !== '' ) {
                try {
                    const response = await axios.post(this.$store.state.urlPage + 'api/review-create', {
                        name: this.name,
                        review: this.review,
                        photo: this.fileImage
                    }, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                        }
                    })
                    if( response ) {
                      alert('Вас отзыв был успешно добавлен, после нажатия ОК вас перекинет на другую страницу')
                      this.$router.push('/admin/panel')
                    }
                }
                catch(e) {
                    alert( e.response.data )
                }
            }
            else {
                alert('Заполните пустые поля и добавьте фотографию')
            }
        }
    },
    mounted() {
    }
}
</script>

<style scoped>
.createReview {
    display: flex;
}
.createReview__content {
    padding: 43px 243px;
}
.createReview__title {
    font-weight: 700;
    font-size: 48px;
    margin-bottom:21px;
    color: #2C3136;
}
.createReview__block {
    display: flex;
    align-items: center;
    margin-bottom: 11px;
}
.createReview__label {
    font-weight: 500;
    font-size: 16px;
    color: #2C3136;
}
.createReview__input {
    border: 1px solid #2C3136;
    border-radius: 5px;
    background: white;
    padding: 15px;
    outline: none;
    min-width: 350px;
    margin-left: 15px;
    font-weight: 500;
    font-size: 14px;
    color: #2C3136;
    transition: all 0.3s ease;
}
.createReview__input:focus {
    border: 1px solid #D7593C;
}
.createReview__area {
    border: 1px solid #2C3136;
    border-radius: 5px;
    background: white;
    padding: 15px;
    outline: none;
    min-width: 350px;
    margin-left: 15px;
    font-weight: 500;
    font-size: 14px;
    color: #2C3136;
}
.createReview__photo {
    display: flex;
    margin-top: 32px;
}
.createReview__name {
    font-weight: 600;
    font-size: 18px;
    color: #2C3136;
}
.createReview__des {
    font-weight: 500;
    font-size: 14px;
    margin: 3px 0;
    color: #868686;
}
.createReview__file {
    margin-top: 20px;
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
}
.createReview__img {
    padding-top: 43px;
    border-radius: 10px;
    max-width: 650px;
}
.createReview__btn {
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
    background: #4CAF50;
    border-radius: 5px; 
    border:none;
    margin-top: 29px;
    padding: 15px 56px;
    transition: all 0.3s ease;
    cursor: pointer;
}
.createReview__btn:hover {
    transform: scale(0.98);
}
</style>