<template>
    <div class="wrap">
        <div class="wrap__content">
            <div class="wrap__header">
                <div class="wrap__logo">
                    <img src="@/assets/img/navbar/mobLogo.svg">
                </div>
                <div class="wrap__close" @click="$emit('close')">
                    <img src="@/assets/img/navbar/close.svg">
                </div>
            </div>
            <div class="wrap__information">
                <div class="wrap__card">
                    <div class="wrap__img">
                        <img src="@/assets/img/navbar/mobMail.svg">
                    </div>
                    <p class="wrap__text">zolenkova@mail.ru</p>
                </div>
                <div class="wrap__card">
                    <div class="wrap__img">
                            <img src="@/assets/img/navbar/mobLocation.svg">
                    </div>
                    <p class="wrap__text">г. Киров, ул. Свободы д. 131 , оф. 2</p>
                </div>
                <div class="wrap__block">
                    <div class="wrap__image">
                        <img src="../assets/img/navbar/mobPhone.svg">
                    </div>
                    <div class="wrap__des">
                        <p class="wrap__phone">+7 (912) 714-88-01</p>
                        <p class="wrap__schedule">Пн-пт с 9:00 до 20:00<br>Сб-вс: Выходной</p>
                    </div>
                </div>
            </div>
            <div class="wrap__nav">
                <ul>
                    <li
                    v-for="(list, idx) in routList"
                    :key="list.path"
                    @click="goMenu(idx)"
                    >{{ list.name }}</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    emits:['close'],
    data() {
        return {
            routList:[
                {path:'/',name:'Главная'},
                { path: '/objects-kv', name: 'Объекты' },
                { path: '/news', name: 'Новости' },
                { path: '/about', name: 'О нас' },
            ]
        }
    },
    methods:{
        goMenu(idx) {
            this.$router.push(this.routList[idx].path)
            this.$emit('close')
        }
    }
}
</script>

<style scoped>
.wrap {
    position: fixed;
    height: 100vh;
    width:100%;
    top: -120%;
    background: #D7593C;
    z-index: 50;
    transition: all 0.3s ease;
}
.wrap__content {
    padding: 30px 50px;
}
.wrap__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 44px;
}
.wrap__card {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}
.wrap__card img {
    display: flex;
    align-items: center;
}
.wrap__text {
    margin-left: 10px;
    font-weight: 500;
    font-size: 15px;
    color: #FFFFFF;
}
.wrap__block {
    display: flex;
    align-items: flex-start;
}
.wrap__des {
    margin-left: 10px;
}
.wrap__phone {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 2px;
    color: #FFFFFF;
}
.wrap__schedule {
    font-weight: 400;
    font-size: 12px;
    color: #FFFFFF;
}
.wrap__nav {
    margin-top: 80px;
}
.wrap__nav ul li {
    list-style-type: none;
    margin-bottom: 16px;
    font-weight: 400;
    font-size: 32px;
    color: #FFFFFF;
}
</style>