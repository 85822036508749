<template>
  <div class="panelItem">
        <div class="panelItem__content">
            <div class="panelItem__left">
                <p class="panelItem__id"><span class="panelItem__span">№</span>1</p>
                <p class="panelItem__id"><span class="panelItem__span">Название:</span>{{ post.name }}</p>
            </div>
            <div class="panelItem__btn">
                <delete-button @click="postDelete"></delete-button>
            </div>
        </div>
      </div>
</template>

<script>
import deleteButton from '@/components/SectionPanel/UI/delete-button.vue'
import axios from 'axios'
export default {
    props: {
        post: {
            type: Object
        },
    },
    methods:{
        postDelete() {
            const response = axios.delete(this.$store.state.urlPage + 'api/post-delete', {
                data:{
                    name:this.post.name
                },
                headers:{
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            })
            if (response) {
                this.$emit('accessDeletePost', this.post.name)
                alert('Пост успешно удален')
            }
        }
    },
    components: { deleteButton },
}
</script>

<style scoped>

</style>