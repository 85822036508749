<template>
  <section-create-post-main></section-create-post-main>
</template>

<script>
import SectionCreatePostMain from '@/components/SectionCreatePost/SectionCreatePostMain.vue'
export default {
    components:{ SectionCreatePostMain }
}
</script>

<style scoped>

</style>