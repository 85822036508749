<template>
    <div class="objectItem">
        <div class="objectItem__content">
            <div class="objectItem__info">
                <div class="objectItem__image">
                    <img :src="mainImg">
                </div>
                <div class="objectItem__block">
                    <div class="objectItem__list">
                        <p class="objectItem__title">{{ item.name }}</p>
                        <p class="objectItem__adress">{{ item.adress }}</p>
                        <p class="objectItem__des">{{ description }}</p>
                    </div>   
                    <div class="objectItem__icon">
                        <p class="objectItem__name">{{ item.surname }}</p>
                        <div class="objectItem__img">
                            <img src="@/assets/logo.svg">
                        </div>
                    </div>
                </div>
            </div>
            <div class="objectItem__price">
                <p class="objectItem__money">{{ price }} ₽</p>
                <button class="objectItem__btn" @click="$router.push('/objects-kv/' + item.codename)">Подробнее</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        item:{
            type:Object
        }
    },
    data(){
        return {
            mainImg:'',
            description:'',
            price:''
        }
    },
    methods:{
        deleteLengthDes( str ) {
           this.description =  str.length <= 155 ? str : str.split('').splice(0, 155).join('') + '...'
        },
    },
    mounted() {
        this.deleteLengthDes( this.item.des )
        this.mainImg = this.$store.state.urlPage  + '/api/' + this.item.photos.split('').splice(this.item.photos.indexOf(`objects`)).join('').replace(/\\/g, "/")
        if( this.item.price.length === 4 ) {
            let res = this.item.price.split('')
            res.splice(1, 0, ' ');
            this.price = res.join('')
        }
        else if( this.item.price.length === 5 ) {
            let res = this.item.price.split('')
            res.splice(2, 0, ' ');
            this.price = res.join('')
        }
        else if( this.item.price.length === 6 ) {
            let res = this.item.price.split('')
            res.splice(3, 0, ' ');
            this.price = res.join('')
        }
        else if (this.item.price.length === 7) {
            let res = this.item.price.split('')
            res.splice(1, 0, ' ');
            res.splice(5, 0, ' ');
            this.price = res.join('')
        }
        else if (this.item.price.length === 8) {
            let res = this.item.price.split('')
            res.splice(2, 0, ' ');
            res.splice(6, 0, ' ');
            this.price = res.join('')
        }
        else {
            this.price = this.item.price
        }
    }
}
</script>

<style scoped>
.objectItem {
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 9px 20px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    margin-bottom:35px;
}
.objectItem__content {
    padding: 17px 26px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.objectItem__info {
    display: flex;
    align-items: flex-start;
    padding-right: 15px;
    word-break:break-all;
}
.objectItem__image img {
    width:226px;
    height: 199px;
    border-radius: 5px;
}
.objectItem__block {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    margin-left: 22px;
}
.objectItem__list {
    height: 145px;
}
.objectItem__title {
    font-weight: 600;
    font-size: 18px;
    height: 24px;
    overflow: hidden;
    color: #2C3136;
}
.objectItem__adress {
    font-weight: 400;
    font-size: 14px;
    max-width: 307px;
    margin-top: 5px;
    height: 38px;
    overflow: hidden;
    color: #2C3136;
}
.objectItem__des {
    font-weight: 400;
    font-size: 14px;
    line-height: 133%;
    max-width: 400px;
    margin-top: 13px;
    height: 46px;
    color: #929292;
}
.objectItem__icon {
    display: flex;
    align-items: center;
    margin-top: 21px;
}
.objectItem__name {
    font-weight: 600;
    font-size: 16px;
    color: #2C3136;
}
.objectItem__img img {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
}
.objectItem__price {
    width: 150px;
}
.objectItem__money {
    font-weight: 600;
    font-size: 20px;
    color: #2C3136;
}

.objectItem__btn {
    position: relative;
    background: #D7593C;
    border-radius: 5px;
    border:none;
    width: 100%;
    padding: 9px 0;
    font-weight: 500;
    font-size: 12px;
    margin-top: 15px;
    color: #FFFFFF;
    z-index: 0;
    transition: all 0.3s ease;
    cursor: pointer;
}
.objectItem__btn:hover {
    transform: scale(0.98);
}
@media(max-width:1040px) {
    .objectItem__block {
    display: flex;
    flex-direction: column;
    }
    .objectItem__adress {
        display: none;
    }
    .objectItem__list {
        height: 0;
    }
    .objectItem__icon {
        margin-top: 30px;
    }
    .objectItem__des {
        display: none;
    }
}
@media(max-width:640px) {
    .objectItem {
        width: 280px;
    }
    .objectItem__content {
        flex-direction: column;
    }
    .objectItem__info {
        flex-direction: column;
        padding: 0;
    }
    .objectItem__block {
    display: flex;
    flex-direction: column;
    margin: 0;
    }
    .objectItem__list {
        height: 0;
    }
    .objectItem__icon {
        margin-top: 30px;
    }
    .objectItem__title {
        font-size: 22px;
        text-align: center;
    
    }
}
</style>