<template>
  <div class="objects">
    <div class="object__content">
        <p class="object__title">{{ ObjectsArray.length }} объявлений</p>
        <div class="objects__header">
            <div class="object__search">
                <input class="object__input" type="text" placeholder="Введите название, адрес или район" v-model="sortedSearch">
            </div>
            <div class="object__modal">
                <div class="object__sorted">
                    <div class="object__icon">
                        <img src="@/assets/img/objects/triangle.svg">
                    </div>
                    <div class="object__text">
                        <div class="object__block" @click="sortedModalOpen = true">
                            <p class="object__by">Сортировка по:</p>
                            <p class="object__word">{{ sortedCurrentWord }}</p>
                        </div>
                        <object-sorted-item 
                        :sortedModal="sortedModal" 
                        :sortedModalOpen="sortedModalOpen"
                        :sortedCurrentWord="sortedCurrentWord"
                        @activeWord="setActiveSortedWord"
                        v-click-away="setAwayClick"
                        ></object-sorted-item>
                    </div>
                </div>
            </div>
        </div>
        <div class="object__isloading" v-if="isLoading">
            <div class="object__menu">
                <div class="object__empty" v-if="sortedSearchArray.length <= 0">
                    <p class="object__empty_text">Пусто</p>
                </div>
                <object-item
                v-for="item in sortedSearchArray"
                :item="item"
                :key="item.id"
                ></object-item>
            </div>
            <div class="objcet__pagination">
                <pagination
                v-if="sortedSearchArray.length !== 0 && sortedSearch.length === 0"
                :sortedSearchArray="ObjectsArray"
                :startIdx="startIdx"
                :endIdx="endIdx"
                :maxIndexProps="maxIndexProps"
                @IndexNext="goNext"
                @IndexPrev="goPrev"
                @IndexBtns="goBtn"
                ></pagination>
                <pagination
                v-else-if="sortedSearchArray.length !== 0 && sortedSearch.length > 0"
                :sortedSearchArray="sortedSearchArray"
                :startIdx="startIdx"
                :endIdx="endIdx"
                :maxIndexProps="maxIndexProps"
                @IndexNext="goNext"
                @IndexPrev="goPrev"
                @IndexBtns="goBtn"
                ></pagination>
            </div>
        </div>
        <div class="object__spinner" v-else>
            <spinner></spinner>
        </div>
    </div>
  </div>
</template>

<script>
import ObjectSortedItem from './Item/ObjectSortedItem.vue'
import ObjectItem from './Item/ObjectItem.vue'
import Pagination from '@/components/Logic/Pagination.vue'
import axios from 'axios'
import Spinner from '@/components/UI/Spinner.vue'

export default {
    components: { ObjectSortedItem, ObjectItem, Pagination, Spinner, },
    data() {
        return {
            sortedSearch:'',
            sortedСondition:'rating',
            sortedCurrentWord:'Популярности',
            sortedModal:[
                {
                    name:'Популярности',sorted:'rating'
                },
                {
                    name: 'Сначала дорогие', sorted: 'priceRich'
                },
                {
                    name: 'Сначала дешевые', sorted: 'priceСheap'
                },
            ],
            sortedModalOpen:false,
            ObjectsArray:[],
            startIdx:0,
            endIdx:15,
            startIndexProps:0,
            endIndexProps:15,
            maxIndexProps:15,
            isLoading:false,
        }
    },
    methods:{
        setActiveSortedWord( word, search ) {
            this.sortedCurrentWord = word
            this.sortedСondition = search
            this.sortedModalOpen = false
        },
        setAwayClick() {
            this.sortedModalOpen = false
        },
        goNext( start, end ) {
            this.startIndexProps = start
            this.endIndexProps = end
            this.startIdx = start
            this.endIdx = end
        },
        goPrev( start, end ) {
            this.startIndexProps = start
            this.endIndexProps = end
            this.startIdx = start
            this.endIdx = end
        },
        goBtn(start, end) {
            this.startIndexProps = start
            this.endIndexProps = end
            this.startIdx = start
            this.endIdx = end
        },
        async getObjects() {
        try {
            const response = await axios.get(this.$store.state.urlPage + 'api/get-objects')
                this.ObjectsArray = await response.data
                this.isLoading = true
                return this.ObjectsArray
        }
        catch(e) {
            this.isLoading = false
            alert(e.response.data)
        }
        }
    },
    computed:{
        sortedModalArray() {
                if (this.sortedСondition === 'rating') return this.ObjectsArray.sort((a, b) => Number(a.rating) - Number(b.rating))
                else if (this.sortedСondition === 'priceRich') return this.ObjectsArray.sort((a, b) => Number(b.price) - Number(a.price))
                else return this.ObjectsArray.sort((a, b) => Number(a.price) - Number(b.price))
        },
        sortedSearchArray() {
            if( this.isLoading ) {
                const newArray = []
                const searchArray = this.sortedSearch.replace(/\s/g, '').toUpperCase()
                for (let i = 0; i < this.sortedModalArray.length; i++) {
                    const resultTitle = this.sortedModalArray[i].name.replace(/\s/g, '').toUpperCase().includes(searchArray)
                    const resultAdress = this.sortedModalArray[i].adress.replace(/\s/g, '').toUpperCase().includes(searchArray)
                    const resultDes = this.sortedModalArray[i].des.replace(/\s/g, '').toUpperCase().includes(searchArray)
                    if (resultTitle === true || resultAdress === true || resultDes === true) {
                        newArray.push(this.sortedModalArray[i])
                    }
                }
                return newArray.slice(this.startIdx, this.endIdx)
            }
        }
    },
    watch:{
        sortedSearch( value ) {
            this.startIndexProps = 0 
            this.endIndexProps = 15
            this.startIdx = 0
            this.endIdx = 15
            this.$store.state.currentIdxObjects = 1
        }
    },
    mounted() {
        this.$store.state.currentIdxObjects = 1
        this.getObjects()
    }
}
</script>

<style scoped>
.object__content {
    padding: 54px 509px;
}
.object__title {
    font-weight: 600;
    font-size: 24px;
    color: #2C3136;
}
.objects__header {
    margin-top: 27px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.object__input {
    background: #FFFFFF;
    border: 1px solid #DFDFDF;
    border-radius: 5px;
    width: 343px;
    padding: 10px 20px;
    outline: none;
    font-weight: 500;
    font-size: 14px;
    color: #2C3136;
}
.object__sorted {
    display: flex;
    align-items: center;
}
.object__icon {
    margin-right: 5px;
}
.object__text {
    position: relative;
}
.object__block {
    display: flex;
    align-items: center;

}
.object__by {
    font-weight: 600;
    font-size: 16px;
    color: #2C3136;
    margin-right: 3px;
    cursor: pointer;
}
.object__word {
    font-weight: 500;
    font-size: 16px;
    border-bottom:1px dashed #D7593C;
    color: #D7593C;
    cursor: pointer;
}
.object__menu {
    margin-top: 35px;
    min-height: 500px;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.object__empty {
    text-align: center;
    min-height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.object__spinner {
    min-height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.object__empty_text {
    font-weight: 600;
    font-size: 32px;
    color: #2C3136;
}
.objcet__pagination {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
@media(max-width:1800px) { 
    .object__content {
         padding: 54px 409px;   
    }
}
@media(max-width:1600px) { 
    .object__content {
         padding: 54px 309px;   
    }
}
@media(max-width:1400px) { 
    .object__content {
         padding: 54px 209px;   
    }
}
@media(max-width:1087px) { 
    .object__content {
         padding: 54px 109px;   
    }
}
@media(max-width:900px) { 
    .object__content {
         padding: 54px 50px;   
    }
}
@media(max-width:800px) { 
    .object__content {
         padding: 54px 50px;   
    }
    .objects__header {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
    }
    .object__sorted {
        margin-top: 30px;
    }
}
@media(max-width:400px) { 
    .object__content {
         padding: 54px 10px;   
    }
    .objects__header {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
    }
    .object__sorted {
        margin-top: 30px;
    }
}
</style>