<template>
  <div class="blockObjects">
    <MyButton @click="$router.push('/admin/create-object')">Создать объект</MyButton>
    <h2 class="blockObjects__empty" v-if="itemsArray.length <= 0">Пусто</h2>
    <div class="blockObjects__items">
        <object-item
        v-for="item in arrayObjects"
        :key="item.id"
        :item="item"
        :idx="item.idx"
        @removeItem="removeObject"
        ></object-item>
    </div>
    <div class="blockObjects__pagination" v-if="itemsArray.length !== 0">
        <pagination
        :sortedSearchArray="itemsArray"
        :startIdx="startIdx"
        :endIdx="endIdx"
        :maxIndexProps="maxIdx"
        @IndexNext="goNext"
        @IndexPrev="goPrev"
        @IndexBtns="goBtns"
        ></pagination>
    </div>
  </div>
</template>

<script>
import MyButton from '@/components/SectionPanel/UI/my-button.vue'
import ObjectItem from '@/components/SectionPanel/Objects/ObjectsItem/ObjectItem.vue'
import Pagination from '@/components/Logic/Pagination.vue'
import axios from 'axios'
export default {
    props:{
        activeStr:{}
    },
    data() {
        return {
            itemsArray:[],
            startIdx:0,
            endIdx:10,
            maxIdx:10,
            counter:0,
        }
    },
    methods:{
        goNext( startIndex, endIndex ) {
            this.startIdx = startIndex
            this.endIdx = endIndex
        },
        goPrev(startIndex, endIndex) {
            this.startIdx = startIndex
            this.endIdx = endIndex
        },
        goBtns(startIndex, endIndex) {
            this.startIdx = startIndex
            this.endIdx = endIndex
        },
        async getAllObjects() {
            try {
                const response = await axios.get(this.$store.state.urlPage + 'api/get-objects',{
                    headers:{
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                })
                this.itemsArray = await response.data
                this.itemsArray.forEach(el => {
                    el.idx = this.counter
                    this.counter += 1
                })
            }
            catch(e) {
                alert( e.response.data )
            }
        },
        removeObject( codename ) {
            if( this.itemsArray.slice(this.startIdx, this.endIdx).length === 1 && this.startIdx !== 0 ) {
                this.itemsArray.splice(this.itemsArray.findIndex(el => el.codename === codename), 1)
                this.startIdx -= 4
                this.endIdx -= 4
                this.$store.state.currentIdxObjects -= 1
            }
            else {
                this.itemsArray.splice(this.itemsArray.findIndex(el => el.codename === codename), 1)
            }
        }
    },
    computed:{
        arrayObjects() {
            return this.itemsArray.slice( this.startIdx, this.endIdx )
        }
    },
    mounted(){
        this.$store.state.currentIdxObjects = 1
        this.getAllObjects()
    },
    components:{ MyButton, ObjectItem, Pagination }
}
</script>

<style scoped>
.blockObjects {
    margin-top: 50px;
}
.blockObjects__items {
    margin-top: 20px;
}
.blockObjects__pagination {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
}
.blockObjects__empty {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    margin-top: 120px;
    color: #2C3136;
}
</style>