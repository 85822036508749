<template>
  <div class="contacts">
    <div class="contacts__content">
        <div class="contacts__header">
            <div class="contacts__image">
                <img src="@/assets/img/about/line2.svg">
            </div>
            <p class="contacts__title"
            data-aos="flip-up" 
            data-aos-duration="800"
            >Наши контакты</p>
        </div>
        <div class="contacts__map">
            <div class="contacts__img"
            data-aos="slide-right" 
            data-aos-duration="800"
            >
                <iframe 
                src="https://yandex.ru/map-widget/v1/?um=constructor%3Aee9103f34e325747f9f4d5d412a951f3132e0ab5f8ab3667e96800abc78a8af0&amp;source=constructor" 
                width="502" 
                height="550" 
                frameborder="0"
                ></iframe>
            </div>
            <div class="contacts__info"
            data-aos="slide-left" 
            data-aos-duration="800"
            >
                <div class="contacts__item">
                    <div class="contacts__icon">
                        <img src="@/assets/img/contacts/1.svg">
                    </div>
                    <p class="contacts__text">+7 (912) 714-88-01</p>
                </div>
                <div class="contacts__item">
                    <div class="contacts__icon">
                        <img src="@/assets/img/contacts/2.svg">
                    </div>
                    <p class="contacts__text">zolenkova@mail.ru</p>
                </div>
                <div class="contacts__item">
                    <div class="contacts__icon">
                        <img src="@/assets/img/contacts/3.svg">
                    </div>
                    <p class="contacts__text">г. Киров, ул. Свободы <br>д. 131 , оф. 2</p>
                </div>
                <div class="contacts__item">
                    <div class="contacts__icon">
                        <img src="@/assets/img/contacts/4.svg">
                    </div>
                    <p class="contacts__text">Пн-пт с 9:00 до 20:00<br> Сб-вс: Выходной</p>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.contacts {
    position: relative;
}
.contacts__content {
    padding: 60px 243px;
}
.contacts__header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.contacts__title {
font-weight: 600;
font-size: 46px;
margin-top: 30px;
color: #2C3136;
}
.contacts__map {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-top: 40px;
}
.contacts__info {
    margin-left: 20px;
}
.contacts__item {
    display: flex;
    align-items: center;
    background: #F2F6FA;
    border-radius: 5px;
    width: 502px;
    height: 127px;
    margin-bottom: 14px;
}
.contacts__icon {
margin-left: 33px;
}
.contacts__icon img {
    display: flex;
    align-items: center;
}
.contacts__text {
font-weight: 400;
font-size: 21px;
margin-left: 18px;
color: #2C3136;
}
@media(max-width:1100px) {
    .contacts__map {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .contacts__img {
        margin-left: 20px;
        margin-bottom: 10px;
    }
}
@media(max-width:900px) {
.contacts__content {
    padding: 60px 20px;
}
.contacts__title {
font-size: 36px;
}
}
@media(max-width:540px) {
.contacts__img iframe {
    width: 400px;
    height: 400px;
}
.contacts__icon img {
    width: 50px;
}
.contacts__item {
    width: 400px;
    height: 127px;
}
.contacts__text {
font-size: 18px;
}
}
@media(max-width:450px) {
.contacts__img iframe {
    width: 300px;
    height: 300px;
}
.contacts__icon img {
    width: 50px;
}
.contacts__item {
    width: 300px;
    height: 127px;
}
.contacts__text {
font-size: 14px;
}
}
@media(max-width:420px) {
    .contacts__title {
font-weight: 600;
font-size: 20px;
margin-top: 30px;
color: #2C3136;
}
}
</style>