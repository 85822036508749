<template>
  <div class="information">
    <div class="information__content">
      <p class="information__title">О нас</p>
      <div class="information__about">
        <div class="information__left">
          <p class="information__des">
            Агенство недвижимости «Золенкова». Профессионалы в сфере
            недвижимости.
          </p>
          <p class="information__text">
            ✅ Мы ценим ваше время и ответственно подходим к каждой сделке
          </p>
          <p class="information__text">
            ✅ Наш подход поможет продать ваш объект недвижимости по максимально
            выгодной цене
          </p>
          <p class="information__text">
            ✅ Сопровождение с момента подбора квартиры, подачи заявки на
            ипотеку до переезда в новое жилье, независимо от того, приедет
            покупатель Вашей квартиры из другого города или им окажется ваш
            сосед, ему потребуется профессиональная помощь
          </p>
          <p class="information__text">
            ✅ Выполняем свою работу в любых ситуациях, даже при отказе банков
            от ипотеки
          </p>
          <p class="information__text">
            ✅ Знаем все о недвижимости г.Кирова и области
          </p>
          <p class="information__text">✅ Опыт работы 15 лет</p>
          <p class="information__text">✅ Гарантия безопасной сделки</p>
          <p class="information__text">
            Каждый день встречаем новых клиентов и помогаем им в решении
            жилищного вопроса. АН «Золенкова» работаем честно, быстро, законно.
          </p>
        </div>
        <div class="information__image">
          <img src="@/assets/img/information/1.png" />
        </div>
      </div>
      <div class="information__achievements">
        <p class="information__title">
          Наши достижения - <br />Ваша уверенность
        </p>
        <div class="information__img">
          <img src="@/assets/img/information/achievements.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.information__content {
  padding: 2.81vw 12.66vw;
}

.information__about {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2.08vw;
}
.information__left {
  margin-right: 2.6vw;
}
.information__title {
  font-weight: 700;
  font-size: 2.4vw;
  color: #2c3136;
}
.information__des {
  font-weight: 600;
  font-size: 1.25vw;
  margin-bottom: 1.82vw;
  color: #2c3136;
}
.information__text {
  font-weight: 500;
  font-size: 0.94vw;
  line-height: 1.15vw;
  margin-bottom: 1.3vw;
  color: #11263a;
}
.information__achievements {
  margin-top: 5.26vw;
}
.information__image img {
  width: 25.42vw;
}
.information__img {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3.13vw;
}
.information__img img {
  width: 66.09vw;
}
@media (max-width: 600px) {
  .information__title {
    font-size: 24px;
  }
}
</style>
