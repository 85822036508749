<template>
  <div class="loading">
    <div class="loading__content">
      <spinner></spinner>
    </div>
  </div>
</template>

<script>
import Spinner from '../UI/Spinner.vue'
export default {
  components: { Spinner },
    data() {
        return {
        arr:[
            'Имя, которому можно доверять...',
            'Репутация, говорящая сама за себя...',
            'Двигайся в правильном направлении — купи недвижимость...',
            'Недвижимость — ваша, забота — наша.',
            'Мы все сделаем за вас.'
        ]
        }
    }
}
</script>

<style scoped>
.loading {
    height: 81vh;
    margin-top: 120px;
}
.loading__content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.is-typed {
  text-align: center;
  margin-top: 50px;
  font-weight: 500;
  font-size: 43px;
  margin-bottom: 50px;
  color: #2C3136;
}

</style>