<template>
    <Transition name="select-fade">
        <div class="objectSorted" v-if="sortedModalOpen === true">
            <div class="objectSorted__content">
                <p class="objectSorted__text" 
                v-for="sorted in sortedModal" 
                :key="sorted.name"
                :class="{ active: sorted.name === sortedCurrentWord }" 
                @click="$emit('activeWord', sorted.name, sorted.sorted)">
                {{ sorted.name }}</p>
            </div>
        </div>
    </Transition>
</template>

<script>
import { Transition } from 'vue';

export default {
    props: {
        sortedModal: {
            type: Array
        },
        sortedModalOpen: {
            type: Boolean
        },
        sortedCurrentWord: {
            type: String,
        }
    },
    components: { Transition }
}
</script>

<style scoped>
.objectSorted {
    position: absolute;
    top:130%;
    left:50%;
    background: #FFFFFF;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.09);
    border-radius: 10px;
    width: 190px;
    z-index: 1;
}
.objectSorted__content {
    padding: 20px 0;   
}
.objectSorted__text {
    padding: 15px 18px;
    font-weight: 500;
    font-size: 14px;
    color: #2C3136;
    transition: all 0.3s ease;
    cursor: pointer;
}
.objectSorted__text:hover {
    color:#FE5F1E;
    background: rgba(254, 95, 30, 0.05);
}
.active {
    color: #FE5F1E;
    background: rgba(254, 95, 30, 0.05);
}
.select-fade-enter-active {
    transition: all 0.3s;
}

.select-fade-leave-active {
    transition: all 0.3s ease;
}

.select-fade-enter-from {
    transform: translateX(20px);
    opacity: 0;
}

.select-fade-leave-to {
    transform: translateX(20px);
    opacity: 0;
}
</style>