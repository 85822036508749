<template>
  <div class="pagination">
    <div class="pagination__content">
        <div>
            <button class="pagination__btn" type="button" @click="prev"><img src="@/assets/img/objects/prev.svg"></button>
        </div>
        <div class="pagination__list">
            <button 
            v-for="starts in listToStart" 
            :key="starts" @click="addButtons(starts)"
            :class="{ activeButton : $store.state.currentIdxObjects === starts }"
            class="pagination__center"
            >
            {{ starts }}
            </button>
        </div>
        <div>
            <button class="pagination__btn" type="button" @click="next"><img src="@/assets/img/objects/next.svg"></button>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    emits: ['IndexNext','IndexPrev','IndexBtns'],
    props:{
        sortedSearchArray:{
            type:Array
        },
        startIdx:{},
        endIdx:{},
        maxIndexProps:{
            type: Number,
        }
    },
    data(){
        return {
            currentPage: 1,
            startIndex:0,
            endIndex:this.endIdx,
            maxIndex:this.maxIndexProps
        }
    },
    methods:{
        next() {
            if (this.endIndex < this.sortedSearchArray.length) {
                this.startIndex += this.maxIndex
                this.endIndex += this.maxIndex
                this.$store.state.currentIdxObjects += 1
                this.$emit( 'IndexNext', this.startIndex, this.endIndex )
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            }
        },
        prev() {
            if (this.startIndex > 0) {
                this.startIndex -= this.maxIndex
                this.endIndex -= this.maxIndex
                this.$store.state.currentIdxObjects -= 1
                this.$emit('IndexPrev', this.startIndex, this.endIndex)
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            }
        },
        addButtons(starts) {
            const oldStartIdx  = this.startIndex
            this.startIndex = (starts - 1) * this.maxIndex
            this.endIndex = starts * this.maxIndex
            this.$store.state.currentIdxObjects = starts
            this.$emit('IndexBtns', this.startIndex, this.endIndex)
            if (oldStartIdx !== this.startIndex) {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            }

        }
    },
    computed:{
        pagesToStart() {
            return Math.ceil(this.sortedSearchArray.length / this.maxIndex)
        },
        listToStart() {
            if (this.pagesToStart <= 5) {
                let array = []
                for (let i = 1; i <= this.pagesToStart; i++) {
                    array.push(i)
                }
                return array
            } 
            else if (this.$store.state.currentIdxObjects > 2 && this.$store.state.currentIdxObjects <= this.pagesToStart - 2) {
                let array = []
                for (let i = this.$store.state.currentIdxObjects - 2; i <= this.$store.state.currentIdxObjects + 2; i++) {
                    array.push(i)
                }
                return array
            } 
            else if (this.$store.state.currentIdxObjects < 3) {
                return [1, 2, 3, 4, 5]
            } 
            else {
                let array = []
                for (let i = this.pagesToStart - 4; i <= this.pagesToStart; i++) {
                    array.push(i)
                }
                return array
            }
        },
    },
}
</script>

<style scoped>
.pagination__content {
    display: flex;
    align-items: center;
}
.pagination__btn {
    background: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    margin: 0 2px;
    cursor: pointer;
}

.pagination__btn:hover {
    transform: scale(0.98);
}
.pagination__list {
    display: flex;
    align-items: center;
}
.pagination__center {
    border-radius: 100%;
    border: 1px solid #AFAFAF;
    font-weight: 400;
    font-size: 14px;
    width: 36px;
    margin: 0 2px;
    height: 36px;
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #2C3136;
    transition: all 0.3s ease;
    cursor: pointer;
}
.pagination__center:hover {
    background: #D7593C;
    border:none;
    color:white;
}
.activeButton {
    background: #D7593C;
    border:none;
    color:white;
}
</style>