<template>
  <div class="singinModal">
    <div class="singinModal__content">
        <div class="singinModal__block">
            <label class="singinModal__label">Почта</label>
            <input class="signingModal__input" type="email" placeholder="Введите E-mail" v-model="mail">
        </div>
        <div class="singinModal__block">
            <label class="singinModal__label">Пароль</label>
            <input class="signingModal__input" type="password" placeholder="Введите пароль" v-model="password">
        </div>
        <button class="signinModal__btn" @click="loginUser">Войти</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
    data() {
        return {
            mail:'',
            password:''
        }
    },
    methods:{
        async loginUser() {
            if( this.mail !== '' && this.password !== '' ) {
                try {
                    const req = await axios.post(this.$store.state.urlPage + 'api/login-user', {
                        mail: this.mail,
                        password: this.password
                    })
                    const response = await req.data
                    if (response) {
                        localStorage.setItem('token',response)
                        this.$router.push('/admin/panel')
                    }
                }
                catch( e ) {
                    alert( e.response.data )
                }
            }
            else {
                alert('Заполните пустые поля')
            }
        }
    }
}
</script>

<style scoped>
.singinModal {
    filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25));
    background: #FFFFFF;
    border-radius: 8px;
    min-width: 390px;
    margin-top: 21px;
    margin-bottom: 50px ;
}
.singinModal__content {
    padding: 24px;
}
.singinModal__block {
    display: flex;
    flex-direction: column;
}
.singinModal__label {
    font-weight: 400;
    font-size: 14px;
    color: #2C3136;
}
.signingModal__input {
    border: 1px solid #EEEEEE;
    border-radius: 4px;
    outline: none;
    padding: 8px;
    margin-top: 8px;
    font-weight: 400;
    font-size: 16px;
    color: #2C3136;
}
.singinModal__block {
    margin-bottom: 24px;
}
.signinModal__btn {
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
    background: #D7593C;
    border-radius: 8px;
    border:none;
    padding: 8px;
    transition: all 0.3s ease;
    cursor: pointer;
}
.signinModal__btn:hover {
    transform: scale(0.98);
}
</style>