<template>
  <div class="object">
    <section-object-main></section-object-main>
  </div>
</template>

<script>
import SectionObjectMain from '@/components/SectionObject/SectionObjectMain.vue'
export default {
  components:{ SectionObjectMain }
}
</script>

<style scoped>

</style>