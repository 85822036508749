<template>
  <div class="blockReviews">
    <MyButton @click="$router.push('/admin/create-review')">Создать отзыв</MyButton>
    <h2 class="blockReviews__empty" v-if="reviewsList.length <= 0">Пусто</h2>
    <div class="blockReviews__items">
        <reviews-item
        v-for="(review, idx) in arrayReviews"
        @accessDelete="accessDelete"
        :key="review.id"
        :review="review"
        :idx="idx"
        ></reviews-item>
    </div>
    <div class="blockReviews__pagination" v-if="reviewsList.length > 0">
        <pagination
        :sortedSearchArray="reviewsList"
        :startIdx="startIdx"
        :endIdx="endIdx"
        :maxIndexProps="maxIdx"
        @IndexNext="goNext"
        @IndexPrev="goPrev"
        @IndexBtns="goBtns"
        ></pagination>
    </div>
  </div>
</template>

<script>
import MyButton from '@/components/SectionPanel/UI/my-button.vue'
import ReviewsItem from '@/components/SectionPanel/Reviews/ReviewsItem/ReviewsItem.vue'
import Pagination from '@/components/Logic/Pagination.vue'
import axios from 'axios'

export default {
    data() {
        return {
            reviewsList:[],
            startIdx: 0,
            endIdx: 4,
            maxIdx: 4,
        }
    },
    methods: {
        goNext(startIndex, endIndex) {
            this.startIdx = startIndex
            this.endIdx = endIndex
        },
        goPrev(startIndex, endIndex) {
            this.startIdx = startIndex
            this.endIdx = endIndex
        },
        goBtns(startIndex, endIndex) {
            this.startIdx = startIndex
            this.endIdx = endIndex
        },
        accessDelete( review ) {
            if( this.reviewsList.slice(this.startIdx, this.endIdx).length === 1 && this.startIdx !== 0 ) {
                this.reviewsList.splice(this.reviewsList.findIndex(el => el.name === review.name), 1)
                this.startIdx -= 4
                this.endIdx -= 4
                this.$store.state.currentIdxObjects -= 1
            }
            else {
                this.reviewsList.splice(this.reviewsList.findIndex(el => el.name === review.name), 1)
            }
        },
        async loadReviews() {
            try {
                const response = await axios.get(this.$store.state.urlPage + 'api/get-reviews',{
                    headers:{
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                })
                return this.reviewsList = await response.data
            }
            catch(e) {
                alert( e.response.data )
            }
        }
    },
    computed: {
        arrayReviews() {
            return this.reviewsList.slice(this.startIdx, this.endIdx)
        }
    },
    mounted() {
        this.$store.state.currentIdxObjects = 1
        this.loadReviews()
    },
    components:{ MyButton, ReviewsItem, Pagination }
}
</script>

<style scoped>
.blockReviews {
    margin-top: 50px;
}
.blockReviews__items {
    height: 425px;
    margin-top: 20px;
}
.blockReviews__pagination {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
}
.blockReviews__empty {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    margin-top: 120px;
    color: #2C3136;
}
</style>