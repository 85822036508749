<template>
  <div class="panelItem">
    <div class="panelItem__content">
        <div class="panelItem__left">
            <p class="panelItem__id"><span class="panelItem__span">№</span>{{ idx + 1 }}</p>
            <img class="panelItem__img" :src="mainImg">
            <p class="panelItem__id"><span class="panelItem__span">Название:</span>{{ item.name }}</p>
            <input v-if="editIdx !== ''" class="panelItem__editPrice" type="number" v-model="editPrice">
        </div>
        <div class="panelItem__btn">
            <button class="panelItem__edit" @click="setPrice">{{ editText }}</button>
            <delete-button @click="deleteObject"></delete-button>
        </div>
    </div>
  </div>
</template>

<script>
import deleteButton from '@/components/SectionPanel/UI/delete-button.vue'
import axios from 'axios'
export default {
  props:{
    item:{
        type:Object
    },
    idx:{}
  },
  data() {
    return {
      editPrice:0,
      editIdx:'',
      editText:'Редактировать цену',
      mainImg:'',
    }
  },
  methods:{
    async deleteObject() {
      try {
        const response = await axios.delete(this.$store.state.urlPage + 'api/object-delete', {
          data: {
            codename: this.item.codename
          },
          headers:{
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        })
        const list = await response.data
        if (list) {
          alert(list)
        }
        this.$emit('removeItem', this.item.codename)
      }
      catch(e) {
        alert(e.response.data)
      }
    },
    async setPrice() {
      if( this.editIdx === '' ) {
        this.editIdx = this.idx
        this.editText = 'Подтвердить'
      }
      else if( this.editIdx !== '' && this.editPrice !== 0 ) {
        const res = await axios({
          method: 'put',
          url: this.$store.state.urlPage + 'api/object-edit',
          data: {
            codename: this.item.codename,
            price: this.editPrice
          }
        })
        const list = await res.data
        if( list ) {
          alert(list)
          this.editPrice = 0
          this.editIdx = ''
          this.editText = 'Редактировать цену'
        }
      }
    }
  },
  mounted(){
    this.mainImg = this.$store.state.urlPage + '/api/' + this.item.photos.split('').splice(this.item.photos.indexOf(`objects`)).join('').replace(/\\/g, "/")
  },
  components: { deleteButton },

}
</script>

<style >
.panelItem {
    width: 100%;
    border: 2px solid #2C3136;
    border-radius: 10px;
    margin-bottom:17px;
}
.panelItem__content {
    padding: 25px 31px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.panelItem__left {
    display: flex;
    align-items: center;
}
.panelItem__id {
    font-weight: 500;
    font-size: 18px;
    margin-right: 50px;
    color: #2C3136;
}
.panelItem__span {
    margin-right: 5px;
    color: #9A9A9A;
}
.panelItem__edit {
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
    background: rgba(70, 163, 88, 1);
    border-radius: 8px;
    border:none;
    margin-right: 20px;
    padding: 8px 31px;
    transition: all 0.3s ease;
    cursor: pointer;
}
.panelItem__edit:hover {
    transform: scale(0.98);
}
.panelItem__editPrice {
  outline: none;
  padding: 5px 10px 5px 10px;
}
.panelItem__img {
  width: 150px;
  height: 140px;
  margin-right: 30px;
  border-radius: 5px;
}
</style>