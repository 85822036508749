<template>
  <div class="notfound">
    <p class="notfound__text">Страница неопределена 404</p>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.notfound {
    min-height: 81vh;
    width: 100%;
}
.notfound__text {
    text-align: center;
    margin-top: 150px;
    font-size: 71px;
    font-weight: 500;
}
</style>