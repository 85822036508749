<template>
    <div class="objectSlider">
        <div class="objectSlider__content">
            <div class="objectSlider__img" >
                <div class="objectSlider__card">
                <img 
                v-for="img in getImg" 
                :key="img.src"
                :src="img.src"
                >
                </div>
            </div>
        </div>
        <div class="objectSlider__prev" @click="prev">
            <img src="@/assets/img/object/prev.svg">
        </div>
        <div class="objectSlider__next" @click="next">
            <img src="@/assets/img/object/next.svg">
        </div>
    </div>
</template>

<script>
export default {
    props:{
        imagesArray:{
            type:Object
        },
        currentIndex: {
            type:Number
        }
    },
    data() {
        return {

        }
    },
    methods:{
        prev() {
            if( this.$store.state.startIdxSlider > 0 ) {
                this.$store.state.startIdxSlider -= 1
                this.$store.state.endIdxSlider -= 1
                this.$store.state.currentIdxSlider -= 1
            }
        },
        next() {
            if( this.$store.state.endIdxSlider < this.imagesArray.length  ) {
                this.$store.state.startIdxSlider += 1
                this.$store.state.endIdxSlider += 1
                this.$store.state.currentIdxSlider += 1
            }
        }
    },
    computed:{
        getImg() {
            return this.imagesArray.slice( this.$store.state.startIdxSlider, this.$store.state.endIdxSlider )
        },
    },
    mounted() {
        this.$store.state.startIdxSlider = 0
        this.$store.state.endIdxSlider = 1
        this.$store.state.currentIdxSlider = 0
    }
}
</script>

<style scoped>
.objectSlider {
    position: relative;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    width: 48.23vw;
}
.objectSlider__img {
    display: flex;
    align-items: center;
    justify-content: center;
}
.objectSlider__card img {
    max-width: 521px;
    height: 408px;
    vertical-align: bottom;
}
.objectSlider__prev {
    position: absolute;
    top: 46%;
    left:10%;
    transition: all 0.3s ease;
    cursor: pointer;
}
.objectSlider__prev:hover {
    transform: scale(0.98);
}
.objectSlider__next {
    position: absolute;
    top: 46%;
    right:10%;
    transition: all 0.3s ease;
    cursor: pointer;
}
.objectSlider__next:hover {
    transform: scale(0.98);
}
@media( max-width:1000px ) {
    .objectSlider__prev {
    position: absolute;
    top: 46%;
    left:3%;
    transition: all 0.3s ease;
    cursor: pointer;
}
.objectSlider__next {
    position: absolute;
    top: 46%;
    right:3%;
    transition: all 0.3s ease;
    cursor: pointer;
}

}
@media( max-width:900px ) {
.objectSlider {
    width: 100%;
}
}
@media(max-width:456px) {
.objectSlider__card img {
    max-width: 421px;
    height: 308px;
    vertical-align: bottom;
}
}
</style>