<template>
    <div class="panel">
        <p class="panel__title">Админ панель</p>
        <div class="panel__choice">
            <choice-panel
            @activeBlock="setActive"
            ></choice-panel>
        </div>
        <block-objects 
        v-if="activeStr === 'Объекты'"
        ></block-objects>
        <block-posts v-else-if="activeStr === 'Посты'"></block-posts>
        <block-reviews v-else></block-reviews>
    </div>
</template>

<script>
import ChoicePanel from '@/components/SectionPanel/Choice/ChoicePanel.vue'
import BlockObjects from '@/components/SectionPanel/Objects/BlockObjects.vue'
import BlockPosts from '@/components/SectionPanel/Posts/BlockPosts.vue'
import BlockReviews from '@/components/SectionPanel/Reviews/BlockReviews.vue'

export default {
  data() {
    return {
        activeStr:'Объекты'
    }
  },
  methods:{
    setActive( word ) {
        this.activeStr = word
    }
  },
  components: { ChoicePanel, BlockObjects, BlockPosts, BlockReviews },
}
</script>

<style scoped>
.panel__title {
    font-weight: 700;
    font-size: 48px;
    color: #2C3136;
}
</style>