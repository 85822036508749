<template>
  <div class="singin">
    <div class="signin__content">
      <p class="singin__title">ВХОД</p>
      <modal></modal>
    </div>
  </div>
</template>

<script>
import Modal from '@/components/SectionVxod/VxodModal/Modal.vue'
export default {
  components: { Modal },

}
</script>

<style scoped>
.singin {
  display: flex;
  align-items: center;
  justify-content: center;
}
.signin__content {
  margin-top: 170px;
}
.singin__title {
  text-align: center;
  font-weight: 700;
  font-size: 48px;
  margin-right: 10px;
  color: #2C3136;
}
</style>